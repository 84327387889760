import React, { useContext } from 'react';
import './main.scss';
import language from '../../config/language';
import { LanguageContext } from '../../LanguageContext';

export default function SectionROI() {
  const [localLang] = useContext(LanguageContext);
  return (
    <div className="sectionROI">
      <p className="text-center font-bold text-white xs:pt-16 md:pt-20 pb-8 f-h1">
        {language.sectionROI.roiCalculator[localLang]}
      </p>
      <div className="text-white" style={{ width: '80%', margin: '0 auto' }}>
        <span data-calculator-id="Z84KaEvm1o72D0Le27y6"></span>
      </div>
      <div className="main-top-cave">
        <img src="/assets/images/top-section2-img.png" />
      </div>
      <div className="main-bottom-cave">
        <img src="/assets/images/bottom-section2-img.png" />
      </div>
    </div>
  );
}
