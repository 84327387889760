import React, { useContext } from 'react';
import language from '../../config/language';
import navLinks from '../../config/constants';
import { LanguageContext } from '../../LanguageContext';

export default function Section5() {
  const [localLang] = useContext(LanguageContext);
  return (
    <div className="section5" id="section5">
      <div className="section5-content">
        <div className="flex flex-wrap justify-center mt-8">
          <a href={navLinks.discord} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/discord.png"
              className="social-item m-3 xs:w-10"
            />
          </a>
          <a href={navLinks.telegram} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/telegram.png"
              className="social-item m-3 xs:w-10"
            />
          </a>
          <a href={navLinks.twitter} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/twitter.png"
              className="social-item m-3 xs:w-10"
            />
          </a>
          <a href={navLinks.youtube} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/y2.png"
              className="social-item m-3 xs:w-10"
            />
          </a>
          <a href={navLinks.medium} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/medium.png"
              className="social-item m-3 xs:w-10"
            />
          </a>
        </div>
        <div className="flex flex-wrap justify-center items-center mt-8 xs:w-4/5 lg:w-3/5 mx-auto">
          {/* <a href={navLinks.presale} target={"_blank"} rel="noreferrer">
            <img
              src="/assets/images/social/pinksale.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a> */}
          {/* <a href={navLinks.binance} target={'_blank'} rel="noreferrer"> */}
          <a href="#" target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/binance.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          <a href={navLinks.certik} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/certik.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          {/* <a href={navLinks.chainlink} target={'_blank'} rel="noreferrer"> */}
          <a href="#" target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/chainlink.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          <a href={navLinks.certik} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/solidproof.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          {/* <a href={navLinks.coinmarketcap} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/coinmarketcap.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          <a href={navLinks.coingecko} target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/coingecko.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a> */}
          {/* <a href={navLinks.buyBLSTLink} target={'_blank'} rel="noreferrer"> */}
          <a href="#" target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/pancakeswap.webp"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          {/* <a href={navLinks.poocoin} target={'_blank'} rel="noreferrer"> */}
          <a href="#" target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/poocoin.png"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
          {/* <a href={navLinks.dextools} target={'_blank'} rel="noreferrer"> */}
          <a href="#" target={'_blank'} rel="noreferrer">
            <img
              src="/assets/images/social/dextools.webp"
              className="social-item m-3 xs:w-24 md:w-36"
            />
          </a>
        </div>

        <div className="text-white p-8">
          <div className="lg:container mx-auto grid lg:grid-cols-3 xs:grid-cols-1">
            <div className="lg:text-left xs:text-center">
              <a
                href={navLinks.company}
                target="_blank"
                rel="noopener noreferrer"
                className="company-title"
              >
                {language.sectionFooter.companyName[localLang]}
              </a>
            </div>
            <div className="text-center"></div>
            <div className="lg:text-right xs:text-center text-sm text-gray-600">
              <a href={navLinks.policy} className="mr-4">
                {language.sectionFooter.privacyPolicy[localLang]}
              </a>
              <a href={navLinks.termAndConditions}>
                {language.sectionFooter.tearmAndConditions[localLang]}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
