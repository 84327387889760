import React from 'react';
// import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainHome from './pages/main/Home';
import LanguageContext from './LanguageContext';

function App() {
  return (
    <LanguageContext>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<MainHome />} />
        </Routes>
      </BrowserRouter>
    </LanguageContext>
  );
}

export default App;
