import classNames from 'classnames';
import React, { useState } from 'react';

import { FaPlay, FaPause } from 'react-icons/fa';
import ParticleBG from '../../components/Layout/ParticleBG';
export default function Section2() {
  const [trailerPlay, setTrailerPlay] = useState(false);

  const handleVideo = () => {
    let video = document.getElementById('main-trailer');
    if (trailerPlay) {
      setTrailerPlay(false);
      video.pause();
    } else {
      setTrailerPlay(true);
      video.currentTime = 0;
      video.muted = false;
      video.play();
    }
  };

  return (
    <div className="section2" id="section2">
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <video autoPlay playsinline muted loop id="main-trailer">
              <source src="https://s3.ca-central-1.amazonaws.com/bigcrypto.game/Big+Crypto+Game+v1.7+final+trailer+for+website.mp4" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
        `,
        }}
      />
      <div className="main-top-cave">
        <img src="/assets/images/top-section2-img.png" />
      </div>
      <div className="main-bottom-cave">
        <img src="/assets/images/bottom-section2-img.png" />
      </div>
      <button
        onClick={() => handleVideo()}
        className={classNames({ 'opacity-0': trailerPlay }, 'trailer-play-btn')}
      >
        {trailerPlay ? <FaPause /> : <FaPlay />}
      </button>
    </div>
  );
}
