import React, { useState, useContext, useEffect } from 'react';
import './NavBar.css';
import { Spin as Hamburger } from 'hamburger-react';
import classNames from 'classnames';
import language from '../../config/language';
import navLinks from '../../config/constants';
import { LanguageContext } from '../../LanguageContext';
import logo from '../../assets/images/logo.png';

const NavBar = (props) => {
  const { setTrailerOpen } = props;
  const [showLogo, setShowLogo] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [localLang, setLocalLang] = useContext(LanguageContext);

  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setShowLogo(window.scrollY > 400)
      );
    }
  }, []);

  const langList = [
    {
      image: 'en.svg',
      lang: 'en',
    },
    {
      image: 'es.svg',
      lang: 'es',
    },
    {
      image: 'de.jpg',
      lang: 'de',
    },
    {
      image: 'fr.svg',
      lang: 'fr',
    },
    {
      image: 'hi.svg',
      lang: 'hi',
    },
    {
      image: 'ig.jpg',
      lang: 'ig',
    },
    {
      image: 'it.jpg',
      lang: 'it',
    },
    {
      image: 'jp.svg',
      lang: 'ja',
    },
    {
      image: 'nl.png',
      lang: 'nl',
    },
    {
      image: 'pl.png',
      lang: 'pl',
    },
    {
      image: 'pt.svg',
      lang: 'pt',
    },
    {
      image: 'ro.png',
      lang: 'ro',
    },
    {
      image: 'ru.svg',
      lang: 'ru',
    },
    {
      image: 'ph.png',
      lang: 'ph',
    },
    {
      image: 'tr.svg',
      lang: 'tr',
    },
    {
      image: 'uk.png',
      lang: 'uk',
    },
    {
      image: 'vi.svg',
      lang: 'vi',
    },
    {
      image: 'zh.svg',
      lang: 'cn',
    },
  ];

  return (
    <header id="nav-bar">
      <div className="nav-bar-container flex flex-col">
        <div className="nav-bar justify-between w-full">
          <img
            src={logo}
            alt="Big Crypto Game"
            className={`xs:h-4/5 sm:h-full lg:hidden xs:block`}
          />

          <div id="nav-list" className="xs:hidden lg:block">
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <img
                src={logo}
                alt="Crypto Legions"
                className={`h-full md:h-3/5 xs:hidden md:mr-2 ${
                  showLogo ? 'lg:block' : 'lg:hidden'
                }`}
              ></img>
              <div className="nav-item" onClick={() => setTrailerOpen(true)}>
                {language.section1.navTitleTrailer[localLang]}
              </div>
              <a
                href="https://docs.bigcrypto.game/roadmap"
                target={'_blank'}
                rel="noreferrer"
                className="nav-item"
              >
                {language.section1.navTitleRoadmap[localLang]}
              </a>
              <a
                href="https://docs.bigcrypto.game/team"
                target={'_blank'}
                rel="noreferrer"
                className="nav-item"
              >
                {language.section1.navTitleTeam[localLang]}
              </a>
              <a href={navLinks.whitePaper} target={'_blank'} rel="noreferrer">
                <div className="nav-item">
                  {language.section1.navTitleWhitepaper[localLang]}
                </div>
              </a>
              <a href={navLinks.whitelist} target={'_blank'} rel="noreferrer">
                <div className="nav-item-green-special whitespace-nowrap">
                  {language.section1.btnWhiteList[localLang]}
                </div>
              </a>
              <a href={navLinks.whitelist} target={'_blank'} rel="noreferrer">
                <div className="nav-item-green-special">
                  {language.section1.navTitlePlay[localLang]}
                </div>
              </a>
            </div>
          </div>

          <div className="flex">
            <div className="hidden md:block">
              <a href={navLinks.agency} target={'_blank'} rel="noreferrer">
                <div className="nav-item-green-special">
                  {language.section1.headerTitle[localLang]}
                </div>
              </a>
            </div>
            <div className="xs:block lg:block lang ml-auto xs:mr-12 lg:mr-0">
              <div>
                <ul>
                  {langList.map((item, index) => (
                    <li
                      key={index}
                      className={classNames({
                        select: localLang === item.lang,
                      })}
                      onClick={() => setLocalLang(item.lang)}
                    >
                      <img
                        width="40"
                        height="40"
                        src={`/assets/images/lang/${item.image}`}
                        alt={item.lang}
                        loading="lazy"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="xs:block lg:hidden ml-2 menu-hamburger">
            <Hamburger
              size
              rounded={true}
              toggle={setDropdownOpen}
              toggled={dropdownOpen}
            />
          </div>
          <div
            className={classNames(
              { hidden: !dropdownOpen },
              'dropdown-menu',
              'f-h2',
              'lg:hidden'
            )}
          >
            <div
              className="nav-item py-2"
              onClick={() => {
                setDropdownOpen(false);
                setTrailerOpen(true);
              }}
            >
              {language.section1.btnTrailer[localLang]}
            </div>
            <div className="nav-item py-2">
              <a href="https://docs.cryptolegions.app/roadmap">
                {language.section1.navTitleRoadmap[localLang]}
              </a>
            </div>
            <div className="nav-item py-2" onClick={() => scrollTo('section4')}>
              {language.section1.navTitleTeam[localLang]}
            </div>
            <a href={navLinks.whitePaper} target={'_blank'} rel="noreferrer">
              <div className="nav-item py-2">
                {language.section1.navTitleWhitepaper[localLang]}
              </div>
            </a>
            <a href={navLinks.whitelist} target={'_blank'} rel="noreferrer">
              <div className="nav-item">
                {language.section1.btnWhiteList[localLang]}
              </div>
            </a>
            <a href={navLinks.whitelist} target={'_blank'} rel="noreferrer">
              <div className="nav-item py-2">
                {language.section1.btnPlay[localLang]}
              </div>
            </a>
          </div>
        </div>
        <div className="text-left ml-4 block md:hidden pt-2 md:pt-0">
          <a href={navLinks.agency} target={'_blank'} rel="noreferrer">
            <div className="nav-item-green-special">
              {language.section1.headerTitle[localLang]}
            </div>
          </a>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
