const section1 = {
  navTitleTrailer: {
    en: "Trailer",
    de: "Anhänger",
    es: "Remolque",
    fr: "Bande annonce",
    hi: "ट्रेलर",
    ig: "Ihe nkiri",
    it: "trailer",
    ja: "トレーラー",
    nl: "aanhangwagen",
    pl: "Przyczepa",
    pt: "Reboque",
    ro: "Remorcă",
    ru: "Трейлер",
    ph: "Trailer",
    tr: "tanıtım videosu",
    uk: "Трейлер",
    vi: "Giới thiệu tóm tắt",
    cn: "预告片",
  },
  navTitleRoadmap: {
    en: "Roadmap",
    de: "Fahrplan",
    es: "Mapa vial",
    fr: "Feuille de route",
    hi: "रोडमैप",
    ig: "Roadmap",
    it: "Tabella di marcia",
    ja: "ロードマップ",
    nl: "stappenplan",
    pl: "Mapa drogowa",
    pt: "Roteiro",
    ro: "Foaia de parcurs",
    ru: "Дорожная карта",
    ph: "Roadmap",
    tr: "Yol Haritası",
    uk: "Дорожня карта",
    vi: "Lộ trình",
    cn: "路线图",
  },
  navTitleTeam: {
    en: "Team",
    de: "Mannschaft",
    es: "Equipo",
    fr: "Équipe",
    hi: "टीम",
    ig: "Otu",
    it: "Squadra",
    ja: "チーム",
    nl: "Team",
    pl: "Zespół",
    pt: "Equipe",
    ro: "Echipă",
    ru: "Команда",
    ph: "Koponan",
    tr: "Takım",
    uk: "Команда",
    vi: "Đội",
    cn: "团队",
  },
  navTitleWhitepaper: {
    en: "Whitepaper",
    de: "Weißes Papier",
    es: "Papel blanco",
    fr: "Papier blanc",
    hi: "सफेद कागज",
    ig: "Akwụkwọ ọcha",
    it: "Carta bianca",
    ja: "白書",
    nl: "Wit papier",
    pl: "Biały papier",
    pt: "Papel branco",
    ro: "Hartie alba",
    ru: "Белая бумага",
    ph: "Puting papel",
    tr: "Beyaz kağıt",
    uk: "Біла книга",
    vi: "Giấy trắng",
    cn: "白皮书",
  },
  navTitlePlay: {
    en: "Play",
    de: "Spielen",
    es: "Tocar",
    fr: "Jouer",
    hi: "खेलें",
    ig: "Gwuo egwu",
    it: "Giocare a",
    ja: "遊ぶ",
    nl: "Toneelstuk",
    pl: "Bawić się",
    pt: "Toque",
    ro: "Joaca",
    ru: "Играть в",
    ph: "Maglaro",
    tr: "Oyna",
    uk: "Грати",
    vi: "Chơi",
    cn: "玩",
  },
  gameTitle1: {
    en: "A P2E Game Made By The Team Of Crypto Legions",
    de: "Ein P2E-Spiel, das vom Team von Crypto Legions mit einem neuen",
    es: "Un juego P2E creado por el equipo de Crypto Legions",
    fr: "Un jeu P2E créé par léquipe de Crypto Legions",
    hi: "एक नया प्ले फॉरएवर अर्न फॉरएवर सिस्टम के साथ क्रिप्टो",
    ig: "Egwuregwu P2E nke Ndị otu Crypto Legions",
    it: "Un gioco P2E realizzato dal team di Crypto Legions",
    ja: "新しい「Play Forever Earn Forever」システムを備えた",
    nl: "Een P2E-game gemaakt door het team van Crypto Legions",
    pl: "Gra P2E stworzona przez zespół Crypto Legions",
    pt: "Um jogo P2E feito pela equipe de Crypto Legions",
    ro: "Un joc P2E creat de echipa Crypto Legions",
    ru: "Игра P2E, созданная командой Crypto Legions",
    ph: "P2E hra vytvořená týmem Crypto Legions",
    tr: "Kripto Lejyonları Ekibi Tarafından Yeni Bir",
    uk: "P2E-гра, створена командою Crypto Legions",
    vi: "Một trò chơi P2E được tạo ra bởi nhóm các quân đoàn tiền điện ",
    cn: "由 Crypto Legions 团队制作的 P2E 游戏",
  },
  gameTitle2: {
    en: "With a New ‘Play Forever Earn Forever’ System",
    de: "„Spielen Sie für immer verdienen Sie für immer“-System entwickelt wurde",
    es: "con un nuevo sistema ‘Juega para siempre Gana para siempre’",
    fr: 'avec un nouveau système "Jouez pour toujours Gagnez pour toujours"',
    hi: "लीजंस की टीम द्वारा बनाया गया एक पी2ई गेम",
    ig: 'mere na sistemụ "Play ruo mgbe ebighị ebi irite ruo mgbe ebighị ebi."',
    it: 'con un nuovo sistema "Gioca per sempre Guadagna per sempre"',
    ja: "Crypto Legions のチームによって作成された P2E ゲーム",
    nl: "Een P2E-game gemaakt door het team van Crypto Legions",
    pl: "z nowym systemem „Play Forever Earn Forever”",
    pt: 'com um novo sistema "Play Forever Earn Forever"',
    ro: "cu un nou sistem „Play Forever Earn Forever”",
    ru: "с новой системой «Играй вечно, зарабатывай вечно»",
    ph: "s novým systémem „Play Forever Earn Forever“",
    tr: '"Sonsuza Kadar Kazanın" Sistemiyle Yapılan Bir P2E Oyunu',
    uk: "із новою системою «Play Forever Earn Forever»",
    vi: 'tử với hệ thống "Chơi mãi mãi kiếm tiền mãi mãi" mới',
    cn: "采用新的“永远玩得永远”系统",
  },
  gameTitle3: {
    en: "System",
    de: "System",
    es: "Sistema",
    fr: "Système",
    hi: "व्यवस्था",
    ig: "Sistemu",
    it: "Sistema",
    ja: "システム",
    nl: "Systeem",
    pl: "System",
    pt: "Sistema",
    ro: "Sistem",
    ru: "Система",
    ph: "Sistema",
    tr: "sistem",
    uk: "система",
    vi: "Hệ thống",
    cn: "系统",
  },
  gameStartTitle: {
    en: "Game and token launch on Tuesday 4 October at 3pm UTC",
    de: "Spiel- und Tokenstart am Dienstag, den 4. Oktober um 15:00 Uhr UTC",
    es: "Lanzamiento del juego y token el martes 4 de octubre a las 3 p.m. UTC",
    fr: "Lancement du jeu et des jetons le mardi 4 octobre à 15h UTC",
    hi: "गेम और टोकन लॉन्च मंगलवार 4 अक्टूबर को अपराह्न 3 बजे UTC",
    ig: "Mmalite egwuregwu na token na Tuesday 4 October na 3pm UTC",
    it: "Lancio di giochi e token martedì 4 ottobre alle 15:00 UTC",
    ja: "10 月 4 日火曜日午後 3 時 (UTC) にゲームとトークンを開始",
    nl: "Game- en tokenlancering op dinsdag 4 oktober om 15.00 uur UTC",
    pl: "Uruchomienie gry i tokena we wtorek 4 października o godzinie 15:00 UTC",
    pt: "Lançamento do jogo e do token na terça-feira, 4 de outubro, às 15h UTC",
    ro: "Lansarea jocului și a jetonului marți, 4 octombrie, la ora 15:00 UTC",
    ru: "Запуск игры и токена во вторник, 4 октября, в 15:00 по всемирному координированному времени.",
    ph: "Paglulunsad ng laro at token sa Martes 4 Oktubre sa 3pm UTC",
    tr: "Oyun ve jeton 4 Ekim Salı günü saat 15:00 UTC'de lansmanı",
    uk: "Запуск гри та токена у вівторок, 4 жовтня, о 15:00 UTC",
    vi: "Trò chơi và mã thông báo ra mắt vào Thứ Ba ngày 4 tháng 10 lúc 3 giờ chiều UTC",
    cn: "世界标准时间 10 月 4 日星期二下午 3 点推出游戏和代币",
  },
  btnBuyBLST: {
    en: "Buy $CRYPTO",
    de: "$CRYPTO kaufen",
    es: "Comprar $CRYPTO",
    fr: "Acheter $CRYPTO",
    hi: "$CRYPTO . खरीदें",
    ig: "Zụrụ $CRYPTO",
    it: "Acquista $CRYPTO",
    ja: "$CRYPTOを購入する",
    nl: "Koop $CRYPTO",
    pl: "Kup $CRYPTO",
    pt: "Compre $CRYPTO",
    ro: "Cumpărați $CRYPTO",
    ru: "Купить $CRYPTO",
    ph: "Bumili ng $CRYPTO",
    tr: "$CRYPTO satın al",
    uk: "Купуйте $CRYPTO",
    vi: "Mua $ BLST",
    cn: "购买 $CRYPTO",
  },
  btnWhiteList: {
    en: "Whitelist Application",
    de: "Whitelist-Anwendung",
    es: "Solicitud de lista blanca",
    fr: "Demande de liste blanche",
    hi: "श्वेतसूची आवेदन",
    ig: "Ngwa Whitelist",
    it: "Applicazione nella lista bianca",
    ja: "ホワイトリスト アプリケーション",
    nl: "Toepassing op de witte lijst",
    pl: "Biała lista aplikacji",
    pt: "Aplicativo de lista de permissões",
    ro: "Aplicație pe lista albă",
    ru: "Приложение белого списка",
    ph: "Whitelist aplikace",
    tr: "Beyaz Liste Başvurusu",
    uk: "Додаток білого списку",
    vi: "Ứng dụng danh sách trắng",
    cn: "白名单申请",
  },
  whtielist: {
    en: "WHITELIST",
    de: "Weiße Liste",
    es: "lista blanca",
    fr: "liste blanche",
    hi: "श्वेत सूची",
    ig: "akwụkwọ ọcha",
    it: "lista bianca",
    ja: "ホワイトリスト",
    nl: "witte lijst",
    pl: "biała lista",
    pt: "lista branca",
    ro: "lista albă",
    ru: "белый список",
    ph: "whitelist",
    tr: "beyaz liste",
    uk: "білий список",
    vi: "danh sách trắng",
    cn: "白名单",
  },
  btnPlay: {
    en: "Play",
    de: "Spielen",
    es: "Tocar",
    fr: "Jouer",
    hi: "खेलें",
    ig: "Gwuo egwu",
    it: "Giocare a",
    ja: "遊ぶ",
    nl: "Toneelstuk",
    pl: "Bawić się",
    pt: "Toque",
    ro: "Joaca",
    ru: "Играть в",
    ph: "Maglaro",
    tr: "Oyna",
    uk: "Грати",
    vi: "Chơi",
    cn: "玩",
  },
  btnTrailer: {
    en: "Trailer",
    de: "Anhänger",
    es: "Remolque",
    fr: "Bande annonce",
    hi: "ट्रेलर",
    ig: "Ihe nkiri",
    it: "trailer",
    ja: "トレーラー",
    nl: "aanhangwagen",
    pl: "Przyczepa",
    pt: "Reboque",
    ro: "Remorcă",
    ru: "Трейлер",
    ph: "Trailer",
    tr: "tanıtım videosu",
    uk: "Трейлер",
    vi: "Giới thiệu tóm tắt",
    cn: "预告片",
  },
  btnPresale: {
    en: "Presale",
    de: "Vorverkauf",
    es: "Preventa",
    fr: "Prévente",
    hi: "पूर्व बिक्री",
    ig: "Presale",
    it: "Prevendita",
    ja: "プレセール",
    nl: "Voorverkoop",
    pl: "Przedsprzedaż",
    pt: "Pré-venda",
    ro: "Prevânzare",
    ru: "Предпродажа",
    ph: "Presale",
    tr: "ön satış",
    uk: "Передпродаж",
    vi: "Bán trước",
    cn: "预售",
  },
  headerTitle: {
    en: "An Official Game by Crypto Games Agency",
    de: "Ein offizielles Spiel der Crypto Games Agency",
    es: "Un juego oficial de Crypto Games Agency",
    fr: "Un jeu officiel par Crypto Games Agency",
    hi: "क्रिप्टो गेम्स एजेंसी द्वारा एक आधिकारिक गेम",
    ig: "Egwuregwu gọọmentị nke ụlọ ọrụ Crypto Games Agency",
    it: "Un gioco ufficiale della Crypto Games Agency",
    ja: "Crypto Games Agencyによる公式ゲーム",
    nl: "Een officieel spel van Crypto Games Agency",
    pl: "Oficjalna gra agencji Crypto Games",
    pt: "Um jogo oficial da Crypto Games Agency",
    ro: "Un joc oficial de Crypto Games Agency",
    ru: "Официальная игра от агентства Crypto Games",
    ph: "Oficiální hra od Crypto Games Agency",
    tr: "Crypto Games Agency Tarafından Resmi Bir Oyun",
    uk: "Офіційна гра від Crypto Games Agency",
    vi: "Một trò chơi chính thức của Crypto Games Agency",
    cn: "Crypto Games Agency 的官方游戏",
  },
};

const sectionRoadmap = {
  phase1: {
    en: [
      "Development of game and economics",
      "Launching social media accounts",
      "Security testing by trusted testers",
      "KYC authentication by SolidProof",
      "Contract audit by CertiK",
      "Private sale",
      "PinkSale presale listing",
    ],
    de: [
      "Entwicklung von Spiel und Wirtschaft",
      "Starten von Social-Media-Konten",
      "Sicherheitstests durch vertrauenswürdige Tester",
      "KYC-Authentifizierung durch SolidProof",
      "Vertragsprüfung durch CertiK",
      "Privatverkauf",
      "PinkSale Vorverkaufsliste",
    ],
    es: [
      "Desarrollo del juego y la economía.",
      "Lanzamiento de cuentas en redes sociales",
      "Pruebas de seguridad realizadas por probadores de confianza",
      "Autenticación KYC por SolidProof",
      "Auditoría de contratos por CertiK",
      "Venta privada",
      "Listado de preventa de PinkSale",
    ],
    fr: [
      "Développement du jeu et économie",
      "Lancement de comptes sur les réseaux sociaux",
      "Tests de sécurité par des testeurs de confiance",
      "Authentification KYC par SolidProof",
      "Audit de contrat par CertiK",
      "Vente privée",
      "Liste de prévente PinkSale",
    ],
    hi: [
      "खेल और अर्थशास्त्र का विकास",
      "सोशल मीडिया अकाउंट लॉन्च करना",
      "विश्वसनीय परीक्षकों द्वारा सुरक्षा परीक्षण",
      "सॉलिडप्रूफ द्वारा केवाईसी प्रमाणीकरण",
      "CertiK . द्वारा अनुबंध लेखा परीक्षा",
      "निजी बिक्री",
      "पिंकसेल प्रीसेल लिस्टिंग",
    ],
    ig: [
      "Mmepe nke egwuregwu na akụnụba",
      "Ịmalite akaụntụ mgbasa ozi mmekọrịta",
      "Nnwale nchekwa nke ndị nyocha tụkwasịrị obi",
      "Nyocha KYC sitere na SolidProof",
      "Nyochaa nkwekọrịta nke CertiK",
      "Ọrịre nkeonwe",
      "PinkSale ndepụta presale",
    ],
    it: [
      "Sviluppo del gioco e dell'economia",
      "Lancio di account sui social media",
      "Test di sicurezza da parte di tester fidati",
      "Autenticazione KYC di SolidProof",
      "Verifica del contratto da parte di CertiK",
      "Vendita tra privati",
      "Elenco di prevendita PinkSale",
    ],
    ja: [
      "ゲームと経済学の発展",
      "ソーシャルメディアアカウントの立ち上げ",
      "信頼できるテスターによるセキュリティテスト",
      "SolidProofによるKYC認証",
      "CertiKによる契約監査",
      "プライベートセール",
      "PinkSale先行販売リスト",
    ],
    nl: [
      "Ontwikkeling van spel en economie",
      "Lancering van sociale media-accounts",
      "Beveiligingstests door vertrouwde testers",
      "KYC-authenticatie door SolidProof",
      "Contractaudit door CertiK",
      "Prive verkoop",
      "PinkSale voorverkoop aanbieding",
    ],
    pl: [
      "Rozwój gier i ekonomii",
      "Uruchamianie kont w mediach społecznościowych",
      "Testowanie bezpieczeństwa przez zaufanych testerów",
      "Uwierzytelnianie KYC przez SolidProof",
      "Audyt umowy przez CertiK",
      "Prywatna sprzedaż",
      "Oferta przedsprzedaży PinkSale",
    ],
    pt: [
      "Desenvolvimento de jogos e economia",
      "Lançamento de contas de mídia social",
      "Testes de segurança por testadores confiáveis",
      "Autenticação KYC por SolidProof",
      "Auditoria de contrato pela CertiK",
      "Venda privada",
      "Listagem de pré-venda PinkSale",
    ],
    ro: [
      "Dezvoltarea jocului și a economiei",
      "Lansarea conturilor de social media",
      "Testare de securitate de către testeri de încredere",
      "Autentificare KYC de către SolidProof",
      "Audit contractual de către CertiK",
      "Vânzare privată",
      "Lista de prevânzare PinkSale",
    ],
    ru: [
      "Развитие игры и экономики",
      "Запуск аккаунтов в социальных сетях",
      "Тестирование безопасности доверенными тестировщиками",
      "Аутентификация KYC с помощью SolidProof",
      "Аудит контракта Сертик",
      "Частная продажа",
      "Предпродажный листинг PinkSale",
    ],
    ph: [
      "Pag-unlad ng laro at ekonomiya",
      "Paglulunsad ng mga social media account",
      "Pagsubok sa seguridad ng mga pinagkakatiwalaang tester",
      "KYC authentication ng SolidProof",
      "Pag-audit ng kontrata ng CertiK",
      "Pribadong pagbebenta",
      "Listahan ng presale ng PinkSale",
    ],
    tr: [
      "Oyun ve ekonominin gelişimi",
      "Sosyal medya hesaplarının açılması",
      "Güvenilir test kullanıcıları tarafından güvenlik testi",
      "SolidProof tarafından KYC kimlik doğrulaması",
      "CertiK tarafından sözleşme denetimi",
      "Özel satış",
      "PinkSale ön satış listesi",
    ],
    uk: [
      "Розвиток гри та економіки",
      "Запуск акаунтів у соціальних мережах",
      "Тестування безпеки надійними тестувальниками",
      "Аутентифікація KYC від SolidProof",
      "Аудит договору від CertiK",
      "Приватний продаж",
      "Список попереднього продажу PinkSale",
    ],
    vi: [
      "Sự phát triển của trò chơi và kinh tế",
      "Khởi chạy tài khoản mạng xã hội",
      "Kiểm tra bảo mật bởi những người kiểm tra đáng tin cậy",
      "Xác thực KYC bằng SolidProof",
      "Kiểm toán hợp đồng bởi CertiK",
      "Bán riêng",
      "Danh sách bán trước PinkSale",
    ],
    cn: [
      "游戏和经济学的发展",
      "启动社交媒体帐户",
      "由受信任的测试人员进行的安全测试",
      "SolidProof 的 KYC 认证",
      "CertiK 的合同审计",
      "私人销售",
      "PinkSale 预售清单",
    ],
  },
  phase2: {
    en: [
      "Launch of token",
      "Full game release at token launch",
      "Listing on CoinMarketCap / CoinGecko",
    ],
    de: [
      "Start des Tokens",
      "Vollständige Spielveröffentlichung beim Start des Tokens",
      "Auflistung auf CoinMarketCap / CoinGecko",
    ],
    es: [
      "Lanzamiento de ficha",
      "Lanzamiento completo del juego en el lanzamiento del token",
      "Listado en CoinMarketCap / CoinGecko",
    ],
    fr: [
      "Lancement de jeton",
      "Sortie complète du jeu au lancement du jeton",
      "Cotation sur CoinMarketCap / CoinGecko",
    ],
    hi: [
      "टोकन का शुभारंभ",
      "टोकन लॉन्च पर पूरा गेम रिलीज",
      "CoinMarketCap / CoinGeko . पर लिस्टिंग",
    ],
    ig: [
      "Mmalite nke token",
      "Ntọhapụ egwuregwu zuru oke na mmalite token",
      "Ndepụta na CoinMarketCap / CoinGecko",
    ],
    it: [
      "Lancio del token",
      "Rilascio completo del gioco al lancio del token",
      "Quotazione su CoinMarketCap / CoinGecko",
    ],
    ja: [
      "トークンの起動",
      "トークン発売時の完全なゲームリリース",
      "CoinMarketCap/CoinGeckoへの掲載",
    ],
    nl: [
      "Lancering van token",
      "Volledige game-release bij token-lancering",
      "Vermelding op CoinMarketCap / CoinGecko",
    ],
    pl: [
      "Uruchomienie tokena",
      "Pełna wersja gry w momencie premiery tokena",
      "Notowanie na CoinMarketCap / CoinGecko",
    ],
    pt: [
      "Lançamento do token",
      "Lançamento do jogo completo no lançamento do token",
      "Listagem no CoinMarketCap / CoinGecko",
    ],
    ro: [
      "Lansarea jetonului",
      "Lansarea completă a jocului la lansarea simbolului",
      "Listare pe CoinMarketCap / CoinGecko",
    ],
    ru: [
      "Запуск токена",
      "Полная версия игры при запуске токена",
      "Листинг на CoinMarketCap / CoinGecko",
    ],
    ph: [
      "Paglunsad ng token",
      "Buong paglabas ng laro sa paglulunsad ng token",
      "Listahan sa CoinMarketCap / CoinGecko",
    ],
    tr: [
      "jetonun lansmanı",
      "Belirteç lansmanında tam oyun sürümü",
      "CoinMarketCap / CoinGecko'da Listeleme",
    ],
    uk: [
      "Запуск токена",
      "Повний випуск гри під час запуску токена",
      "Листинг на CoinMarketCap / CoinGecko",
    ],
    vi: [
      "Khởi chạy mã thông báo",
      "Toàn bộ trò chơi phát hành khi khởi chạy mã thông báo",
      "Liệt kê trên CoinMarketCap / CoinGecko",
    ],
    cn: [
      "推出代币",
      "代币发布时发布完整游戏",
      "在 CoinMarketCap / CoinGecko 上市",
    ],
  },
  phase3: {
    en: [
      "Growing community",
      "First rounds of community airdrops",
      "Initial marketing plan execution",
      "Partnerships with influencers and giveaway competitions",
      "Collaborations with high quality BSC projects",
      "Exchange listings",
      "Improvements to support a bigger player base",
    ],
    de: [
      "Wachsende Gemeinschaft",
      "Erste Runden der Community-Airdrops",
      "Erste Umsetzung des Marketingplans",
      "Partnerschaften mit Influencern und Gewinnspiele",
      "Zusammenarbeit mit hochwertigen BSC-Projekten",
      "Börsennotierungen",
      "Verbesserungen zur Unterstützung einer größeren Spielerbasis",
    ],
    es: [
      "Comunidad en crecimiento",
      "Primeras rondas de lanzamientos aéreos comunitarios",
      "Ejecución del plan de marketing inicial",
      "Colaboraciones con influencers y concursos de regalos",
      "Colaboraciones con proyectos BSC de alta calidad",
      "listados de intercambio",
      "Mejoras para admitir una base de jugadores más grande",
    ],
    fr: [
      "Communauté grandissante",
      "Premières séries de parachutages communautaires",
      "Exécution du plan marketing initial",
      "Partenariats avec des influenceurs et concours de cadeaux",
      "Collaborations avec des projets BSC de haute qualité",
      "Listes d'échange",
      "Améliorations pour prendre en charge une plus grande base de joueurs",
    ],
    hi: [
      "बढ़ता हुआ समुदाय",
      "सामुदायिक एयरड्रॉप्स का पहला दौर",
      "प्रारंभिक विपणन योजना निष्पादन",
      "प्रभावशाली और सस्ता प्रतियोगिताओं के साथ साझेदारी",
      "उच्च गुणवत्ता वाली बीएससी परियोजनाओं के साथ सहयोग",
      "एक्सचेंज लिस्टिंग",
      "एक बड़े खिलाड़ी आधार का समर्थन करने के लिए सुधार",
    ],
    ig: [
      "Obodo na-eto eto",
      "Agba nke mbụ nke ikuku ikuku obodo",
      "Mmezu atụmatụ azụmaahịa izizi",
      "Mmekọrịta ya na ndị na-eme ihe na asọmpi inye onyinye",
      "Mmekọrịta na ọrụ BSC dị elu",
      "Ndepụta mgbanwe",
      "Mmelite iji kwado ntọala ọkpụkpọ ka ukwuu",
    ],
    it: [
      "Comunità in crescita",
      "Primi round di lanci comunitari",
      "Esecuzione iniziale del piano di marketing",
      "Partnership con influencer e concorsi giveaway",
      "Collaborazioni con progetti BSC di alta qualità",
      "Inserzioni di scambio",
      "Miglioramenti per supportare una base di giocatori più ampia",
    ],
    ja: [
      "成長するコミュニティ",
      "コミュニティエアドロップの最初のラウンド",
      "初期のマーケティング計画の実行",
      "インフルエンサーとのパートナーシップと景品コンテスト",
      "高品質のBSCプロジェクトとのコラボレーション",
      "交換リスト",
      "より大きなプレーヤーベースをサポートするための改善",
    ],
    nl: [
      "groeiende gemeenschap",
      "Eerste rondes van community-airdrops",
      "Initiële uitvoering van het marketingplan",
      "Partnerships met influencers en winacties",
      "Samenwerkingen met hoogwaardige BSC-projecten",
      "Vermeldingen uitwisselen",
      "Verbeteringen om een groter spelersbestand te ondersteunen",
    ],
    pl: [
      "Rosnąca społeczność",
      "Pierwsze rundy zrzutów społeczności",
      "Wstępna realizacja planu marketingowego",
      "Partnerstwa z influencerami i konkursy gratisowe",
      "Współpraca przy wysokiej jakości projektach BSC",
      "Wymiany ofert",
      "Ulepszenia wspierające większą bazę graczy",
    ],
    pt: [
      "Comunidade em crescimento",
      "Primeiras rodadas de lançamentos comunitários",
      "Execução inicial do plano de marketing",
      "Parcerias com influenciadores e concursos de brindes",
      "Colaborações com projetos BSC de alta qualidade",
      "Listagens de troca",
      "Melhorias para suportar uma base de jogadores maior",
    ],
    ro: [
      "Comunitate în creștere",
      "Primele runde de airdrops comunitare",
      "Executarea inițială a planului de marketing",
      "Parteneriate cu influenceri și concursuri de giveaway",
      "Colaborări cu proiecte BSC de înaltă calitate",
      "Listări de schimb",
      "Îmbunătățiri pentru a sprijini o bază mai mare de jucători",
    ],
    ru: [
      "Растущее сообщество",
      "Первые раунды аирдропов сообщества",
      "Выполнение первоначального маркетингового плана",
      "Партнерство с инфлюенсерами и розыгрыши призов",
      "Сотрудничество с высококачественными проектами BSC",
      "Листинги биржи",
      "Улучшения для поддержки большей базы игроков",
    ],
    ph: [
      "Lumalagong komunidad",
      "Mga unang round ng community airdrops",
      "Paunang pagpapatupad ng plano sa marketing",
      "Pakikipagtulungan sa mga influencer at giveaway na kumpetisyon",
      "Pakikipagtulungan sa mataas na kalidad na mga proyekto ng BSC",
      "Palitan ng mga listahan",
      "Mga pagpapabuti upang suportahan ang isang mas malaking base ng manlalaro",
    ],
    tr: [
      "büyüyen topluluk",
      "Topluluk airdroplarının ilk turları",
      "İlk pazarlama planının yürütülmesi",
      "Etkileyiciler ve eşantiyon yarışmaları ile ortaklıklar",
      "Yüksek kaliteli BSC projeleri ile işbirlikleri",
      "Değişim listeleri",
      "Daha büyük bir oyuncu tabanını desteklemek için iyileştirmeler",
    ],
    uk: [
      "Зростаюча спільнота",
      "Перші раунди спільноти airdrops",
      "Початкове виконання плану маркетингу",
      "Партнерство з інфлюенсерами та конкурси розіграшів",
      "Співпраця з високоякісними проектами BSC",
      "Обмін списки",
      "Покращення для підтримки більшої бази гравців",
    ],
    vi: [
      "Cộng đồng đang phát triển",
      "Các đợt airdrop cộng đồng đầu tiên",
      "Thực hiện kế hoạch tiếp thị ban đầu",
      "Hợp tác với những người có ảnh hưởng và các cuộc thi tặng quà",
      "Hợp tác với các dự án BSC chất lượng cao",
      "Trao đổi danh sách",
      "Cải tiến để hỗ trợ cơ sở người chơi lớn hơn",
    ],
    cn: [
      "成长中的社区",
      "第一轮社区空投",
      "初始营销计划执行",
      "与影响者的合作伙伴关系和赠品比赛",
      "与高质量的 BSC 项目合作",
      "交易所上市",
      "改进以支持更大的玩家群",
    ],
  },
  phase4: {
    en: [
      "Adding new interactive game mode",
      "Charity support",
      "Documentary production starts",
      "More community airdrops",
      "Mass marketing",
    ],
    de: [
      "Hinzufügen eines neuen interaktiven Spielmodus",
      "Wohltätigkeitsunterstützung",
      "Die Dokumentarproduktion beginnt",
      "Mehr Community-Airdrops",
      "Massenweise Vermarktung",
    ],
    es: [
      "Agregar nuevo modo de juego interactivo",
      "apoyo de caridad",
      "Comienza la producción del documental",
      "Más lanzamientos desde el aire de la comunidad",
      "Marketing masivo",
    ],
    fr: [
      "Ajout d'un nouveau mode de jeu interactif",
      "Soutien caritatif",
      "Début de la production du documentaire",
      "Plus de parachutages communautaires",
      "Marketing de masse",
    ],
    hi: [
      "नया इंटरेक्टिव गेम मोड जोड़ना",
      "चैरिटी सपोर्ट",
      "डॉक्यूमेंट्री प्रोडक्शन शुरू",
      "अधिक सामुदायिक एयरड्रॉप",
      "मास मार्केटिंग",
    ],
    ig: [
      "Na-agbakwunye ụdị egwuregwu mmekọrịta ọhụrụ",
      "Nkwado ọrụ ebere",
      "Mwepụta akwụkwọ na-amalite",
      "Imirikiti ikuku obodo ọzọ",
      "Ịre ahịa oke",
    ],
    it: [
      "Aggiunta di una nuova modalità di gioco interattiva",
      "Sostegno di beneficenza",
      "Inizia la produzione del documentario",
      "Altri lanci per la comunità",
      "Marketing di massa",
    ],
    ja: [
      "新しいインタラクティブゲームモードの追加",
      "チャリティーサポート",
      "ドキュメンタリー制作開始",
      "その他のコミュニティエアドロップ",
      "マスマーケティング",
    ],
    nl: [
      "Nieuwe interactieve spelmodus toevoegen",
      "liefdadigheidsondersteuning",
      "Documentaireproductie van start",
      "Meer community-airdrops",
      "Massamarketing",
    ],
    pl: [
      "Dodanie nowego interaktywnego trybu gry",
      "Wsparcie charytatywne",
      "Rozpoczęcie produkcji dokumentów",
      "Więcej zrzutów społeczności",
      "Marketing masowy",
    ],
    pt: [
      "Adicionando novo modo de jogo interativo",
      "Apoio de caridade",
      "Começa a produção do documentário",
      "Mais lançamentos da comunidade",
      "Marketing de massa",
    ],
    ro: [
      "Adăugarea unui nou mod de joc interactiv",
      "Sprijin caritabil",
      "Începe producția de documentare",
      "Mai multe airdrops comunitare",
      "Marketing de masa",
    ],
    ru: [
      "Добавление нового интерактивного игрового режима",
      "Благотворительная поддержка",
      "Начало документального производства",
      "Больше раздач сообщества",
      "Массовый маркетинг",
    ],
    ph: [
      "Pagdaragdag ng bagong interactive na mode ng laro",
      "Suporta sa kawanggawa",
      "Nagsisimula ang paggawa ng dokumentaryo",
      "Higit pang mga airdrop ng komunidad",
      "Mass marketing",
    ],
    tr: [
      "Yeni etkileşimli oyun modu ekleme",
      "hayır kurumu desteği",
      "Belgesel üretimi başlıyor",
      "Daha fazla topluluk airdrop'u",
      "Kitlesel pazarlama",
    ],
    uk: [
      "Додавання нового інтерактивного режиму гри",
      "Благодійна підтримка",
      "Початок документального виробництва",
      "Більше рейдів спільноти",
      "Масовий маркетинг",
    ],
    vi: [
      "Thêm chế độ trò chơi tương tác mới",
      "Hỗ trợ từ thiện",
      "Bắt đầu sản xuất phim tài liệu",
      "Thêm airdrop cộng đồng",
      "Tiếp thị đại chúng",
    ],
    cn: [
      "添加新的互动游戏模式",
      "慈善支持",
      "纪录片制作开始",
      "更多社区空投",
      "大众营销",
    ],
  },
  phase5: {
    en: [
      "Continued development of the game",
      "Documentary release and promotional push",
      "Game-branded metaverse",
      "3D monster hunts (PvE mode)",
      "Implementation of community ideas into the game",
    ],
    de: [
      "Weiterentwicklung des Spiels",
      "Dokumentarveröffentlichung und Werbeschub",
      "Metaverse mit Spielmarke",
      "3D-Monsterjagden (PvE-Modus)",
      "Umsetzung von Community-Ideen in das Spiel",
    ],
    es: [
      "Desarrollo continuo del juego.",
      "Estreno documental y empuje promocional",
      "Metaverso con la marca del juego",
      "Cacerías de monstruos en 3D (modo PvE)",
      "Implementación de ideas de la comunidad en el juego.",
    ],
    fr: [
      "Développement continu du jeu",
      "Sortie documentaire et push promotionnel",
      "Métaverse de marque de jeu",
      "Chasses aux monstres 3D (mode PvE)",
      "Implémentation des idées de la communauté dans le jeu",
    ],
    hi: [
      "खेल का निरंतर विकास",
      "वृत्तचित्र रिलीज और प्रचार पुश",
      "गेम-ब्रांडेड मेटावर्स",
      "3D राक्षस शिकार (PvE मोड)",
      "खेल में सामुदायिक विचारों का कार्यान्वयन",
    ],
    ig: [
      "Na-aga n'ihu mmepe nke egwuregwu",
      "Mwepụta akwụkwọ na nkwado nkwado",
      "Metaverse ejiri akara egwuregwu",
      "ịchụ nta anụ ọhịa 3D (ụdị PvE)",
      "Mmejuputa echiche obodo n'ime egwuregwu ahụ",
    ],
    it: [
      "Sviluppo continuo del gioco",
      "Rilascio documentario e spinta promozionale",
      "Metaverso con marchio di gioco",
      "Cacce ai mostri 3D (modalità PvE)",
      "Implementazione delle idee della comunità nel gioco",
    ],
    ja: [
      "ゲームの継続的な開発",
      "ドキュメンタリーリリースとプロモーションプッシュ",
      "ゲームブランドのメタバース",
      "3Dモンスターハント（PvEモード）",
      "ゲームへのコミュニティアイデアの実装",
    ],
    nl: [
      "Verdere ontwikkeling van het spel",
      "Documentaire release en promotionele push",
      "Game-branded metaverse",
      "3D-monsterjachten (PvE-modus)",
      "Implementatie van community-ideeën in het spel",
    ],
    pl: [
      "Dalszy rozwój gry",
      "Wydanie dokumentalne i push promocyjny",
      "Metaverse pod marką gry",
      "Polowanie na potwory 3D (tryb PvE)",
      "Wdrażanie pomysłów społeczności do gry",
    ],
    pt: [
      "Desenvolvimento contínuo do jogo",
      "Lançamento de documentário e impulso promocional",
      "Metaverso com a marca do jogo",
      "Caça aos monstros 3D (modo PvE)",
      "Implementação de ideias da comunidade no jogo",
    ],
    ro: [
      "Dezvoltarea continuă a jocului",
      "Lansare documentară și promovare",
      "Metaversul marca jocului",
      "Vânătoare de monștri 3D (mod PvE)",
      "Implementarea ideilor comunității în joc",
    ],
    ru: [
      "Продолжение развития игры",
      "Документальный выпуск и рекламный толчок",
      "Метавселенная под брендом игры",
      "3D-охота на монстров (режим PvE)",
      "Внедрение идей сообщества в игру",
    ],
    ph: [
      "Patuloy na pag-unlad ng laro",
      "Pagpapalabas ng dokumentaryo at push na pang-promosyon",
      "Metaverse na may tatak ng laro",
      "3D monster hunts (PvE mode)",
      "Pagpapatupad ng mga ideya ng komunidad sa laro",
    ],
    tr: [
      "Oyunun devam eden gelişimi",
      "Belgesel yayın ve promosyon itme",
      "Oyun markalı meta veri deposu",
      "3D canavar avları (PvE modu)",
      "Topluluk fikirlerinin oyuna uygulanması",
    ],
    uk: [
      "Продовження розвитку гри",
      "Документальний випуск та рекламний штовхач",
      "Метавсесвіт під ігровим брендом",
      "3D полювання на монстрів (режим PvE)",
      "Втілення в гру ідей спільноти",
    ],
    vi: [
      "Tiếp tục phát triển trò chơi",
      "Phát hành tài liệu và đẩy mạnh quảng cáo",
      "Game metaverse mang thương hiệu",
      "Săn quái vật 3D (chế độ PvE)",
      "Triển khai các ý tưởng của cộng đồng vào trò chơi",
    ],
    cn: [
      "游戏的持续开发",
      "纪录片发布和宣传推送",
      "游戏品牌的元宇宙",
      "3D怪物狩猎（PvE模式）",
      "将社区理念落实到游戏中",
    ],
  },
};

const sectionTeam = {
  sectionMainTeam: {
    en: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny is the crypto-currency expert of the team, with vast experience and knowledge of P2E game mechanics and the Binance Smart Chain ecosystem. He has several years of experience within the e-commerce space and will be in charge of community building and marketing for Crypto Legions. He lives and breathes crypto and after becoming a successful cryptocurrency trader. His goal is to use the money he’s made to provide a sustainable income source to many more crypto fans.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark has over 20 years experience in business, entrepreneurship, IT sales & marketing, and IT recruitment. He built several thriving businesses, and created multiple communication training programs. He’s also an award-winning film producer/director, breaking records in fundraising, and developing movies for the top streaming platforms. His next aim is to create this crypto-currency game and feature the tangible results in a documentary that will educate the masses how to navigate the NFT gaming space.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie is a net pioneer with over 30 years in tech and 26 years online. He has served as CEO and CTO for numerous Web3 startups and works with well-known Fortune 500 companies including Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil, and First Republic Bank. Johnnie lives in Redondo Beach and loves all things JavaScript, Rust, and Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto has over 5 years of experience in blockchain engineering and 8 years of experience in front-end engineering. As a blockchain engineer, he already built many Defi ecosystems in the form of DApps, P2E games and NFT marketplaces on several blockchains. He has been working as a lead developer for the last 2 years for many blockchain cryptocurrency companies.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake worked in blockchain engineering for 4 years, and has 10 years of experience in full stack development. As a senior blockchain developer, he has built many DApps, NFT smart contracts and marketplaces, crypto games, and DAOs. He always delivers high quality, fast speed, excellent communication and creativity for the project.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel is the tech-savvy professional full-stack blockchain engineer with over 8 years experience from scratch to deploy. He has deep knowledge of oracle systems and multi-chain, and has built various Defi projects like Dex’s and on-chain asset marketplaces, etc. Clean, well-commented code, and good communication skills are his superpowers.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul is a blockchain engineer with 7 years experience in full-stack development, and 3 years as a blockchain engineer. He built many blockchain projects such as NFT minting, NFT marketplaces, NFT Games, DAO, Defi, and DApps. He built many projects from scratch. Front-end development with SPA frameworks are one of his main skills.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal is a Cloud Architect with over 12 years of experience using that cloud as well as 20 years of IT experience. During that time, he has created several successful and award-winning companies as well as worked with multiple others to lead them to success. He loves cloud engineering due to its possible automations and limitless possibilities.",
      },
    ],
    de: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny ist der Krypto-Währungsexperte des Teams mit umfassender Erfahrung und Kenntnis der P2E-Spielmechanik und des Binance Smart Chain-Ökosystems. Er verfügt über mehrjährige Erfahrung im E-Commerce-Bereich und wird für den Community-Aufbau und das Marketing von Crypto Legions verantwortlich sein. Er lebt und atmet Krypto und nachdem er ein erfolgreicher Kryptowährungshändler geworden ist. Sein Ziel ist es, das verdiente Geld zu verwenden, um vielen weiteren Krypto-Fans eine nachhaltige Einkommensquelle zu bieten.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark verfügt über mehr als 20 Jahre Erfahrung in den Bereichen Wirtschaft, Unternehmertum, IT-Vertrieb und -Marketing sowie IT-Rekrutierung. Er baute mehrere florierende Unternehmen auf und erstellte mehrere Kommunikationstrainingsprogramme. Er ist auch ein preisgekrönter Filmproduzent/Regisseur, der Rekorde im Fundraising bricht und Filme für die besten Streaming-Plattformen entwickelt. Sein nächstes Ziel ist es, dieses Kryptowährungsspiel zu entwickeln und die greifbaren Ergebnisse in einem Dokumentarfilm zu präsentieren, der die Massen darüber aufklärt, wie man sich im NFT-Gaming-Bereich zurechtfindet.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie ist ein Netzpionier mit über 30 Jahren Erfahrung in der Technik und 26 Jahren Online. Er war CEO und CTO für zahlreiche Web3-Startups und arbeitet mit bekannten Fortune-500-Unternehmen zusammen, darunter Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil und First Republic Bank. Johnnie lebt in Redondo Beach und liebt JavaScript, Rust und Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto hat über 5 Jahre Erfahrung im Blockchain-Engineering und 8 Jahre Erfahrung im Front-End-Engineering. Als Blockchain-Ingenieur hat er bereits viele Defi-Ökosysteme in Form von DApps, P2E-Spielen und NFT-Marktplätzen auf mehreren Blockchains aufgebaut. Er hat in den letzten 2 Jahren als leitender Entwickler für viele Blockchain-Kryptowährungsunternehmen gearbeitet.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake arbeitete 4 Jahre im Blockchain-Engineering und verfügt über 10 Jahre Erfahrung in der Full-Stack-Entwicklung. Als leitender Blockchain-Entwickler hat er viele DApps, intelligente NFT-Verträge und -Marktplätze, Kryptospiele und DAOs entwickelt. Er liefert immer hohe Qualität, hohe Geschwindigkeit, exzellente Kommunikation und Kreativität für das Projekt.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel ist der technisch versierte professionelle Full-Stack-Blockchain-Ingenieur mit über 8 Jahren Erfahrung von Grund auf neu. Er verfügt über fundierte Kenntnisse in Oracle-Systemen und Multi-Chain und hat verschiedene Defi-Projekte wie Dex's und On-Chain-Asset-Marktplätze usw. aufgebaut. Sauberer, gut kommentierter Code und gute Kommunikationsfähigkeiten sind seine Superkräfte.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul ist ein Blockchain-Ingenieur mit 7 Jahren Erfahrung in der Full-Stack-Entwicklung und 3 Jahren als Blockchain-Ingenieur. Er baute viele Blockchain-Projekte wie NFT-Minting, NFT-Marktplätze, NFT-Spiele, DAO, Defi und DApps auf. Er baute viele Projekte von Grund auf neu. Frontend-Entwicklung mit SPA-Frameworks gehört zu seinen Kernkompetenzen.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal ist ein Cloud-Architekt mit über 12 Jahren Erfahrung in der Nutzung dieser Cloud sowie 20 Jahren IT-Erfahrung. In dieser Zeit hat er mehrere erfolgreiche und preisgekrönte Unternehmen gegründet und mit mehreren anderen zusammengearbeitet, um sie zum Erfolg zu führen. Er liebt Cloud Engineering wegen seiner möglichen Automatisierungen und grenzenlosen Möglichkeiten.",
      },
    ],
    es: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny es el experto en criptomonedas del equipo, con vasta experiencia y conocimiento de la mecánica del juego P2E y el ecosistema Binance Smart Chain. Tiene varios años de experiencia en el espacio del comercio electrónico y estará a cargo de la creación de comunidades y el marketing de Crypto Legions. Vive y respira criptomonedas y después de convertirse en un exitoso comerciante de criptomonedas. Su objetivo es utilizar el dinero que ha ganado para proporcionar una fuente de ingresos sostenible a muchos más fanáticos de las criptomonedas.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark tiene más de 20 años de experiencia en negocios, emprendimiento, ventas y marketing de TI y contratación de TI. Construyó varios negocios prósperos y creó múltiples programas de capacitación en comunicación. También es un productor/director de cine galardonado, que rompe récords en recaudación de fondos y desarrolla películas para las principales plataformas de transmisión. Su próximo objetivo es crear este juego de criptomonedas y presentar los resultados tangibles en un documental que educará a las masas sobre cómo navegar en el espacio de los juegos NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie es un pionero de la red con más de 30 años en tecnología y 26 años en línea. Se ha desempeñado como director ejecutivo y director de tecnología de numerosas empresas emergentes de Web3 y trabaja con conocidas empresas de Fortune 500, incluidas Google, IBM, New York Times, BCGDV, la ciudad de Nueva York, Navient, Shell Oil y First Republic Bank. Johnnie vive en Redondo Beach y ama todo lo relacionado con JavaScript, Rust y Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto tiene más de 5 años de experiencia en ingeniería blockchain y 8 años de experiencia en ingeniería front-end. Como ingeniero de blockchain, ya creó muchos ecosistemas Defi en forma de DApps, juegos P2E y mercados NFT en varias blockchains. Ha estado trabajando como desarrollador principal durante los últimos 2 años para muchas empresas de criptomonedas blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake trabajó en ingeniería de cadenas de bloques durante 4 años y tiene 10 años de experiencia en desarrollo de pila completa. Como desarrollador sénior de blockchain, ha creado muchas DApps, mercados y contratos inteligentes NFT, criptojuegos y DAO. Siempre entrega alta calidad, alta velocidad, excelente comunicación y creatividad para el proyecto.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel es un ingeniero de blockchain de pila completa profesional experto en tecnología con más de 8 años de experiencia desde cero hasta la implementación. Tiene un profundo conocimiento de los sistemas Oracle y multicadena, y ha creado varios proyectos Defi como Dex y mercados de activos en cadena, etc. Sus superpoderes son código limpio y bien comentado y buenas habilidades de comunicación.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul es ingeniero de blockchain con 7 años de experiencia en desarrollo full-stack y 3 años como ingeniero de blockchain. Creó muchos proyectos de cadena de bloques, como la acuñación de NFT, los mercados de NFT, los juegos de NFT, DAO, Defi y DApps. Construyó muchos proyectos desde cero. El desarrollo front-end con frameworks SPA es una de sus principales habilidades.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal es un arquitecto de la nube con más de 12 años de experiencia en el uso de esa nube y 20 años de experiencia en TI. Durante ese tiempo, ha creado varias empresas exitosas y galardonadas, y ha trabajado con muchas otras para llevarlas al éxito. Le encanta la ingeniería en la nube por sus posibles automatizaciones y posibilidades ilimitadas.",
      },
    ],
    fr: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny est l'expert en crypto-monnaie de l'équipe, avec une vaste expérience et connaissance des mécanismes de jeu P2E et de l'écosystème Binance Smart Chain. Il a plusieurs années d'expérience dans le domaine du commerce électronique et sera en charge de la création de communautés et du marketing pour Crypto Legions. Il vit et respire la crypto et après être devenu un trader de crypto-monnaie prospère. Son objectif est d'utiliser l'argent qu'il a gagné pour fournir une source de revenus durable à de nombreux autres fans de crypto.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark a plus de 20 ans d'expérience dans les affaires, l'entrepreneuriat, les ventes et le marketing informatiques et le recrutement informatique. Il a bâti plusieurs entreprises florissantes et créé plusieurs programmes de formation en communication. Il est également un producteur/réalisateur de films primé, battant des records de collecte de fonds et développant des films pour les meilleures plateformes de streaming. Son prochain objectif est de créer ce jeu de crypto-monnaie et de présenter les résultats tangibles dans un documentaire qui apprendra aux masses comment naviguer dans l'espace de jeu NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie est un pionnier du net avec plus de 30 ans dans la technologie et 26 ans en ligne. Il a été PDG et directeur technique de nombreuses startups Web3 et travaille avec des sociétés bien connues du Fortune 500, notamment Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil et First Republic Bank. Johnnie vit à Redondo Beach et aime tout ce qui concerne JavaScript, Rust et Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto a plus de 5 ans d'expérience en ingénierie blockchain et 8 ans d'expérience en ingénierie frontale. En tant qu'ingénieur blockchain, il a déjà construit de nombreux écosystèmes Defi sous la forme de DApps, de jeux P2E et de marketplaces NFT sur plusieurs blockchains. Il travaille en tant que développeur principal depuis 2 ans pour de nombreuses sociétés de crypto-monnaie blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake a travaillé dans l'ingénierie blockchain pendant 4 ans et possède 10 ans d'expérience dans le développement de piles complètes. En tant que développeur senior de blockchain, il a construit de nombreux DApps, contrats et marchés intelligents NFT, jeux cryptographiques et DAO. Il offre toujours une qualité élevée, une vitesse rapide, une excellente communication et de la créativité pour le projet.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel est l'ingénieur professionnel de la chaîne de blocs complète et féru de technologie avec plus de 8 ans d'expérience de zéro au déploiement. Il a une connaissance approfondie des systèmes oracle et multi-chaînes, et a construit divers projets Defi comme Dex et les marchés d'actifs en chaîne, etc. Un code propre et bien commenté et de bonnes compétences en communication sont ses superpuissances.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul est un ingénieur blockchain avec 7 ans d'expérience dans le développement full-stack et 3 ans en tant qu'ingénieur blockchain. Il a construit de nombreux projets de blockchain tels que la frappe NFT, les marchés NFT, NFT Games, DAO, Defi et DApps. Il a construit de nombreux projets à partir de zéro. Le développement front-end avec les frameworks SPA est l'une de ses principales compétences.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal est un architecte cloud avec plus de 12 ans d'expérience dans l'utilisation de ce cloud ainsi que 20 ans d'expérience informatique. Au cours de cette période, il a créé plusieurs entreprises prospères et primées et a travaillé avec plusieurs autres pour les mener au succès. Il aime l'ingénierie cloud en raison de ses automatisations possibles et de ses possibilités illimitées.",
      },
    ],
    hi: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "डैनी टीम का क्रिप्टो-मुद्रा विशेषज्ञ है, जिसके पास पी2ई गेम मैकेनिक्स और बिनेंस स्मार्ट चेन इकोसिस्टम का व्यापक अनुभव और ज्ञान है। उन्हें ई-कॉमर्स क्षेत्र में कई वर्षों का अनुभव है और वे क्रिप्टो लीजंस के लिए सामुदायिक भवन और मार्केटिंग के प्रभारी होंगे। वह क्रिप्टो में रहता है और सांस लेता है और एक सफल क्रिप्टोक्यूरेंसी व्यापारी बनने के बाद। उनका लक्ष्य कई और क्रिप्टो प्रशंसकों को एक स्थायी आय स्रोत प्रदान करने के लिए उनके द्वारा किए गए धन का उपयोग करना है।",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "शार्क को व्यापार, उद्यमिता, आईटी बिक्री और विपणन, और आईटी भर्ती में 20 से अधिक वर्षों का अनुभव है। उन्होंने कई संपन्न व्यवसायों का निर्माण किया, और कई संचार प्रशिक्षण कार्यक्रम बनाए। वह एक पुरस्कार विजेता फिल्म निर्माता / निर्देशक भी हैं, जिन्होंने धन उगाहने में रिकॉर्ड तोड़ दिया है, और शीर्ष स्ट्रीमिंग प्लेटफॉर्म के लिए फिल्में विकसित कर रहे हैं। उनका अगला उद्देश्य इस क्रिप्टो-मुद्रा गेम को बनाना है और एक वृत्तचित्र में ठोस परिणाम पेश करना है जो जनता को शिक्षित करेगा कि एनएफटी गेमिंग स्पेस को कैसे नेविगेट किया जाए।",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "जॉनी एक नेट पायनियर है, जिसके पास 30 से अधिक वर्षों से टेक में और 26 वर्षों से ऑनलाइन है। उन्होंने कई वेब 3 स्टार्टअप के लिए सीईओ और सीटीओ के रूप में काम किया है और Google, आईबीएम, न्यूयॉर्क टाइम्स, बीसीजीडीवी, सिटी ऑफ न्यूयॉर्क, नेवियंट, शेल ऑयल और फर्स्ट रिपब्लिक बैंक सहित प्रसिद्ध फॉर्च्यून 500 कंपनियों के साथ काम किया है। जॉनी रेडोंडो बीच में रहता है और उसे जावास्क्रिप्ट, रस्ट और यूनिटी3डी सभी चीजें पसंद हैं।",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "अल्बर्टो को ब्लॉकचेन इंजीनियरिंग में 5 साल से अधिक का अनुभव है और फ्रंट-एंड इंजीनियरिंग में 8 साल का अनुभव है। एक ब्लॉकचेन इंजीनियर के रूप में, उन्होंने पहले से ही कई ब्लॉकचेन पर डीएपी, पी2ई गेम और एनएफटी मार्केटप्लेस के रूप में कई डेफी इकोसिस्टम का निर्माण किया है। वह पिछले 2 वर्षों से कई ब्लॉकचेन क्रिप्टोक्यूरेंसी कंपनियों के लिए एक प्रमुख डेवलपर के रूप में काम कर रहा है।",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "युयाके ने 4 साल के लिए ब्लॉकचेन इंजीनियरिंग में काम किया है, और पूर्ण स्टैक विकास में 10 साल का अनुभव है। एक वरिष्ठ ब्लॉकचेन डेवलपर के रूप में, उन्होंने कई डीएपी, एनएफटी स्मार्ट कॉन्ट्रैक्ट और मार्केटप्लेस, क्रिप्टो गेम और डीएओ बनाए हैं। वह हमेशा परियोजना के लिए उच्च गुणवत्ता, तेज गति, उत्कृष्ट संचार और रचनात्मकता प्रदान करता है।",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "एंजेल तकनीक की समझ रखने वाला पेशेवर फुल-स्टैक ब्लॉकचेन इंजीनियर है, जिसके पास खरोंच से लेकर तैनाती तक 8 वर्षों का अनुभव है। उन्हें ओरेकल सिस्टम और मल्टी-चेन का गहरा ज्ञान है, और उन्होंने डेक्स और ऑन-चेन एसेट मार्केटप्लेस आदि जैसे विभिन्न डेफी प्रोजेक्ट्स का निर्माण किया है। स्वच्छ, अच्छी तरह से टिप्पणी किए गए कोड और अच्छे संचार कौशल उनकी महाशक्तियां हैं।",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "पॉल एक ब्लॉकचेन इंजीनियर है, जिसके पास फुल-स्टैक डेवलपमेंट में 7 साल का अनुभव है, और ब्लॉकचेन इंजीनियर के रूप में 3 साल का अनुभव है। उन्होंने एनएफटी मिंटिंग, एनएफटी मार्केटप्लेस, एनएफटी गेम्स, डीएओ, डेफी और डीएपी जैसे कई ब्लॉकचेन प्रोजेक्ट बनाए। उन्होंने खरोंच से कई परियोजनाओं का निर्माण किया। एसपीए ढांचे के साथ फ्रंट-एंड विकास उनके मुख्य कौशल में से एक है।",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "रफाल एक क्लाउड आर्किटेक्ट है जिसके पास उस क्लाउड का उपयोग करने का 12 वर्षों का अनुभव है और साथ ही 20 वर्षों का आईटी अनुभव है। उस समय के दौरान, उन्होंने कई सफल और पुरस्कार विजेता कंपनियों का निर्माण किया और साथ ही कई अन्य लोगों के साथ काम करके उन्हें सफलता तक पहुंचाया। वह क्लाउड इंजीनियरिंग को उसके संभावित ऑटोमेशन और असीम संभावनाओं के कारण पसंद करता है।",
      },
    ],
    ig: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny bụ onye ọkachamara crypto-currency nke otu ahụ, nwere ahụmahụ dị ukwuu na ihe ọmụma nke usoro egwuregwu P2E na gburugburu ebe obibi Binance Smart Chain. O nwere ahụmahụ ọtụtụ afọ n'ime oghere e-azụmahịa ma na-ahụ maka iwu obodo na ịzụ ahịa maka Crypto Legions. Ọ na-ebi ma na-eku ume crypto na mgbe ọ ghọchara onye ahịa cryptocurrency na-aga nke ọma. Ebumnuche ya bụ iji ego o mere iji nye ọtụtụ ndị na-akwado crypto ndị ọzọ isi iyi ego na-adigide.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark nwere ihe karịrị afọ 20 ahụmahụ na azụmahịa, ịzụ ahịa, IT ahịa & ahịa, na IT mbanye. Ọ wuru ọtụtụ azụmaahịa na-eme nke ọma, ma mepụta ọtụtụ mmemme ọzụzụ nkwukọrịta. Ọ bụkwa onye na-emepụta ihe nkiri/onye ntụzi ihe nkiri na-enweta ihe nrite, na-emebi ndekọ n'ịkwakọba ego, na imepe ihe nkiri maka nyiwe mgbasa ozi kacha elu. Ebumnuche ya bụ ịmepụta egwuregwu crypto-currency a ma gosipụta nsonaazụ a na-ahụ anya na akwụkwọ akụkọ nke ga-akụziri ndị mmadụ ka ha na-agagharị na oghere egwuregwu NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie bụ ọsụ ụzọ netwọk nwere ihe karịrị afọ 30 na teknụzụ yana afọ 26 n'ịntanetị. Ọ rụrụ ọrụ dị ka CEO na CTO maka ọtụtụ Web3 mmalite na-arụ ọrụ na a maara nke ọma Fortune 500 ụlọ ọrụ gụnyere Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil, na First Republic Bank. Johnnie bi na Redondo Beach ma hụ ihe niile Javascript, Rust na Unity3D n'anya.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto nwere ihe karịrị 5 afọ nke ahụmahụ na blockchain engineering na 8 afọ nke ahụmahụ na n'ihu-ọgwụgwụ engineering. Dị ka onye injinia blockchain, o wularị ọtụtụ gburugburu ebe obibi Defi n'ụdị DApps, egwuregwu P2E na ebe ahịa NFT na ọtụtụ blockchain. Ọ na-arụ ọrụ dị ka onye mmepụta ihe maka afọ 2 gara aga maka ọtụtụ ụlọ ọrụ cryptocurrency blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake rụrụ ọrụ na blockchain engineering maka afọ 4, ma nwee ahụmịhe afọ 10 na mmepe nchịkọta zuru oke. Dị ka onye nrụpụta blockchain dị elu, o wuola ọtụtụ DApps, NFT smart contracts na ebe ahịa, egwuregwu crypto, na DAO. Ọ na-enyekarị àgwà dị elu, ngwa ngwa ngwa ngwa, nkwurịta okwu magburu onwe ya na mmepụta ihe maka ọrụ ahụ.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel bụ onye injinia na-ahụ maka teknụzụ zuru oke tojupụtara blockchain nwere ahụmịhe karịrị afọ 8 site na ọkọ iji bute ya. Ọ nwere ihe ọmụma miri emi nke usoro okwu ọnụ na multi-chain, ma wuo ọrụ Defi dị iche iche dị ka Dex's na on-chain asset marketplaces, wdg. Dị ọcha, koodu nkọwa nke ọma, na ezigbo nkwurịta okwu bụ ike ya.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul bụ onye injinia blockchain nwere ahụmịhe afọ 7 na mmepe zuru oke, yana afọ 3 dị ka onye injinia blockchain. Ọ rụrụ ọtụtụ blockchain ọrụ dị ka NFT minting, NFT ahịa, NFT Games, DAO, Defi, na DApps. O wuru ọtụtụ ọrụ site na mmalite. Mmepe n'ihu na usoro SPA bụ otu n'ime nkà ya bụ isi.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal bụ onye na-ahụ maka igwe ojii nwere ahụmịhe karịrị afọ 12 iji igwe ojii ahụ yana ahụmịhe IT afọ 20. N'ime oge ahụ, o mepụtala ọtụtụ ụlọ ọrụ na-eme nke ọma na ndị na-enweta ihe nrite yana soro ọtụtụ ndị ọzọ rụọ ọrụ iji duga ha ịga nke ọma. Ọ hụrụ injinia igwe ojii n'anya n'ihi akpaaka nwere ike ime yana ohere na-enweghị njedebe.",
      },
    ],
    it: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny è l'esperto di criptovalute del team, con una vasta esperienza e conoscenza delle meccaniche di gioco P2E e dell'ecosistema Binance Smart Chain. Ha diversi anni di esperienza nell'ambito dell'e-commerce e sarà responsabile della creazione di comunità e del marketing per Crypto Legions. Vive e respira criptovalute e dopo essere diventato un trader di criptovalute di successo. Il suo obiettivo è utilizzare i soldi che ha guadagnato per fornire una fonte di reddito sostenibile a molti più fan delle criptovalute.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark ha oltre 20 anni di esperienza in affari, imprenditorialità, vendite e marketing IT e reclutamento IT. Ha costruito diverse attività fiorenti e ha creato più programmi di formazione sulla comunicazione. È anche un pluripremiato produttore/regista di film, record di raccolta fondi e sviluppo di film per le migliori piattaforme di streaming. Il suo prossimo obiettivo è creare questo gioco di criptovalute e presentare i risultati tangibili in un documentario che educherà le masse a navigare nello spazio di gioco NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie è un pioniere della rete con oltre 30 anni nella tecnologia e 26 anni online. È stato CEO e CTO per numerose startup Web3 e lavora con note aziende Fortune 500 tra cui Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil e First Republic Bank. Johnnie vive a Redondo Beach e ama tutto ciò che riguarda JavaScript, Rust e Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto ha oltre 5 anni di esperienza nell'ingegneria blockchain e 8 anni di esperienza nell'ingegneria front-end. In qualità di ingegnere blockchain, ha già costruito molti ecosistemi Defi sotto forma di DApp, giochi P2E e mercati NFT su diversi blockchain. Negli ultimi 2 anni ha lavorato come lead developer per molte società di criptovalute blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake ha lavorato nell'ingegneria blockchain per 4 anni e ha 10 anni di esperienza nello sviluppo di stack completi. In qualità di sviluppatore blockchain senior, ha creato molte DApp, contratti e mercati intelligenti NFT, giochi crittografici e DAO. Offre sempre alta qualità, velocità elevata, comunicazione eccellente e creatività per il progetto.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel è l'ingegnere blockchain professionale esperto di tecnologia con oltre 8 anni di esperienza da zero a implementare. Ha una profonda conoscenza dei sistemi Oracle e multi-catena e ha costruito vari progetti Defi come Dex's e mercati di asset on-chain, ecc. Codice pulito e ben commentato e buone capacità di comunicazione sono i suoi superpoteri.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul è un ingegnere blockchain con 7 anni di esperienza nello sviluppo full-stack e 3 anni come ingegnere blockchain. Ha costruito molti progetti blockchain come il conio NFT, i mercati NFT, NFT Games, DAO, Defi e DApps. Ha costruito molti progetti da zero. Lo sviluppo front-end con framework SPA è una delle sue principali competenze.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal è un Cloud Architect con oltre 12 anni di esperienza nell'utilizzo di quel cloud e 20 anni di esperienza nell'IT. Durante quel periodo, ha creato diverse aziende di successo e pluripremiate e ha lavorato con molte altre per portarle al successo. Ama l'ingegneria del cloud per le sue possibili automazioni e possibilità illimitate.",
      },
    ],
    ja: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Dannyは、チームの暗号通貨の専門家であり、P2Eゲームの仕組みとBinanceSmartChainエコシステムに関する豊富な経験と知識を持っています。彼はeコマース分野で数年の経験があり、CryptoLegionsのコミュニティ構築とマーケティングを担当します。彼は暗号通貨のトレーダーとして成功した後、暗号通貨を生きて呼吸しています。彼の目標は、彼が稼いだお金を使って、より多くの暗号ファンに持続可能な収入源を提供することです。",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Sharkは、ビジネス、起業家精神、ITセールスおよびマーケティング、IT採用で20年以上の経験があります。 彼はいくつかの繁栄するビジネスを構築し、複数のコミュニケーショントレーニングプログラムを作成しました。 彼はまた、受賞歴のある映画プロデューサー/ディレクターであり、資金調達の記録を更新し、トップストリーミングプラットフォーム向けの映画を開発しています。 彼の次の目的は、この暗号通貨ゲームを作成し、NFTゲームスペースをナビゲートする方法を大衆に教育するドキュメンタリーで具体的な結果を紹介することです。",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "ジョニーは、30年以上の技術と26年のオンラインのネットパイオニアです。彼は数多くのWeb3スタートアップのCEOおよびCTOを務め、Google、IBM、New York Times、BCGDV、City of New York、Navient、Shell Oil、FirstRepublicBankなどの有名なFortune500企業と協力しています。ジョニーはレドンドビーチに住んでいて、JavaScript、Rust、Unity3Dのすべてが大好きです。",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Albertoは、ブロックチェーンエンジニアリングで5年以上の経験があり、フロントエンドエンジニアリングで8年以上の経験があります。ブロックチェーンエンジニアとして、彼はすでにいくつかのブロックチェーン上にDApp、P2Eゲーム、NFTマーケットプレイスの形で多くのDefiエコシステムを構築しました。彼は過去2年間、多くのブロックチェーン暗号通貨会社のリード開発者として働いてきました。",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyakeはブロックチェーンエンジニアリングに4年間携わっており、フルスタック開発で10年の経験があります。シニアブロックチェーン開発者として、彼は多くのDApp、NFTスマートコントラクトとマーケットプレイス、暗号ゲーム、およびDAOを構築してきました。彼は常にプロジェクトに高品質、高速、優れたコミュニケーションと創造性を提供します。",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "エンジェルは、技術に精通したプロのフルスタックブロックチェーンエンジニアであり、ゼロから展開まで8年以上の経験があります。彼はオラクルシステムとマルチチェーンについて深い知識を持っており、DexやオンチェーンアセットマーケットプレイスなどのさまざまなDefiプロジェクトを構築してきました。クリーンでコメントの多いコードと優れたコミュニケーションスキルが彼の超能力です。",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paulは、フルスタック開発で7年の経験があり、ブロックチェーンエンジニアとして3年のブロックチェーンエンジニアです。彼は、NFTミンティング、NFTマーケットプレイス、NFTゲーム、DAO、Defi、DAppsなどの多くのブロックチェーンプロジェクトを構築しました。彼は多くのプロジェクトをゼロから構築しました。 SPAフレームワークを使用したフロントエンド開発は、彼の主なスキルの1つです。",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafalはクラウドアーキテクトであり、そのクラウドの使用に12年以上の経験があり、ITの経験も20年あります。その間、彼はいくつかの成功した受賞歴のある会社を設立し、他の複数の会社と協力してそれらを成功に導きました。彼は、自動化の可能性と無限の可能性のために、クラウドエンジニアリングが大好きです。",
      },
    ],
    nl: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny is de cryptovaluta-expert van het team, met uitgebreide ervaring en kennis van P2E-spelmechanica en het Binance Smart Chain-ecosysteem. Hij heeft meerdere jaren ervaring op het gebied van e-commerce en zal verantwoordelijk zijn voor community building en marketing voor Crypto Legions. Hij leeft en ademt crypto en is een succesvolle cryptocurrency-handelaar geworden. Zijn doel is om het geld dat hij heeft verdiend te gebruiken om nog veel meer cryptofans een duurzame inkomstenbron te bieden.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark heeft meer dan 20 jaar ervaring in business, ondernemerschap, IT sales & marketing en IT recruitment. Hij bouwde verschillende bloeiende bedrijven en creëerde meerdere communicatietrainingsprogramma's. Hij is ook een bekroonde filmproducent/regisseur, die records verbreekt op het gebied van fondsenwerving en films ontwikkelt voor de beste streamingplatforms. Zijn volgende doel is om dit crypto-valutaspel te maken en de tastbare resultaten te laten zien in een documentaire die de massa leert hoe ze door de NFT-gameruimte moeten navigeren.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie is een netto-pionier met meer dan 30 jaar in technologie en 26 jaar online. Hij heeft gediend als CEO en CTO voor tal van Web3-startups en werkt met bekende Fortune 500-bedrijven, waaronder Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil en First Republic Bank. Johnnie woont in Redondo Beach en houdt van alles wat met JavaScript, Rust en Unity3D te maken heeft.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto heeft meer dan 5 jaar ervaring in blockchain-engineering en 8 jaar ervaring in front-end engineering. Als blockchain-engineer bouwde hij al veel Defi-ecosystemen in de vorm van DApps, P2E-games en NFT-marktplaatsen op verschillende blockchains. Hij heeft de afgelopen 2 jaar als hoofdontwikkelaar gewerkt voor veel blockchain-cryptocurrency-bedrijven.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake werkte 4 jaar in blockchain-engineering en heeft 10 jaar ervaring in full-stackontwikkeling. Als senior blockchain-ontwikkelaar heeft hij veel DApps, NFT smart contracts en marktplaatsen, cryptogames en DAO's gebouwd. Hij levert altijd hoge kwaliteit, hoge snelheid, uitstekende communicatie en creativiteit voor het project.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel is de technisch onderlegde professionele full-stack blockchain-engineer met meer dan 8 jaar ervaring vanaf het begin tot implementatie. Hij heeft diepgaande kennis van Oracle-systemen en multi-chain, en heeft verschillende Defi-projecten gebouwd, zoals Dex's en on-chain activamarkten, enz. Schone, goed becommentarieerde code en goede communicatieve vaardigheden zijn zijn superkrachten.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul is een blockchain engineer met 7 jaar ervaring in full-stack development en 3 jaar als blockchain engineer. Hij bouwde veel blockchain-projecten zoals NFT-minting, NFT-marktplaatsen, NFT Games, DAO, Defi en DApps. Hij bouwde veel projecten van de grond af. Front-end ontwikkeling met SPA-frameworks is een van zijn belangrijkste vaardigheden.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal is een Cloud Architect met meer dan 12 jaar ervaring in het gebruik van die cloud en 20 jaar IT-ervaring. In die tijd heeft hij verschillende succesvolle en bekroonde bedrijven opgericht en met meerdere anderen samengewerkt om ze naar succes te leiden. Hij houdt van cloud engineering vanwege de mogelijke automatiseringen en onbegrensde mogelijkheden.",
      },
    ],
    pl: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny jest ekspertem ds. kryptowalut w zespole, posiadającym ogromne doświadczenie i wiedzę na temat mechaniki gier P2E oraz ekosystemu Binance Smart Chain. Ma kilkuletnie doświadczenie w przestrzeni e-commerce i będzie odpowiedzialny za budowanie społeczności i marketing dla Crypto Legions. Żyje i oddycha krypto, a po tym, jak został odnoszącym sukcesy traderem kryptowalut. Jego celem jest wykorzystanie pieniędzy, które zarobił, aby zapewnić trwałe źródło dochodu dla znacznie większej liczby fanów kryptowalut.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark ma ponad 20-letnie doświadczenie w biznesie, przedsiębiorczości, sprzedaży i marketingu IT oraz rekrutacji IT. Zbudował kilka dobrze prosperujących firm i stworzył wiele programów szkoleniowych z zakresu komunikacji. Jest także nagradzanym producentem/reżyserem filmowym, bijącym rekordy w zbieraniu funduszy i tworzeniu filmów dla najlepszych platform streamingowych. Jego kolejnym celem jest stworzenie tej gry kryptowalutowej i przedstawienie namacalnych wyników w filmie dokumentalnym, który nauczy masy, jak poruszać się po przestrzeni gier NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie jest pionierem sieci z ponad 30-letnim doświadczeniem technicznym i 26-letnim online. Pełnił funkcję CEO i CTO dla wielu startupów Web3 i współpracuje ze znanymi firmami z listy Fortune 500, w tym Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil i First Republic Bank. Johnnie mieszka w Redondo Beach i kocha wszystkie rzeczy JavaScript, Rust i Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto ma ponad 5-letnie doświadczenie w inżynierii blockchain i 8-letnie doświadczenie w inżynierii front-end. Jako inżynier blockchain zbudował już wiele ekosystemów Defi w postaci DApps, gier P2E i marketplace’ów NFT na kilku blockchainach. Przez ostatnie 2 lata pracował jako główny programista dla wielu firm zajmujących się kryptowalutami blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake pracował w inżynierii blockchain przez 4 lata i ma 10-letnie doświadczenie w tworzeniu pełnego stosu. Jako starszy programista blockchain zbudował wiele DApps, inteligentnych kontraktów NFT i rynków, gier kryptograficznych i DAO. Zawsze zapewnia wysoką jakość, szybkość, doskonałą komunikację i kreatywność dla projektu.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel jest doświadczonym technologicznie profesjonalnym inżynierem full-stack blockchain z ponad 8-letnim doświadczeniem od zera do wdrożenia. Ma głęboką wiedzę na temat systemów Oracle i wielołańcuchowych oraz zbudował różne projekty Defi, takie jak Dex i rynki aktywów w łańcuchu itp. Czysty, dobrze komentowany kod i dobre umiejętności komunikacyjne to jego supermoce.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul jest inżynierem blockchain z 7-letnim doświadczeniem w rozwoju pełnego stosu i 3 latami jako inżynier blockchain. Zbudował wiele projektów blockchain, takich jak NFT minting, NFT Marketplaces, NFT Games, DAO, Defi i DApps. Zbudował wiele projektów od podstaw. Jedną z jego głównych umiejętności jest programowanie front-end z wykorzystaniem frameworków SPA.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafał jest Architektem Chmury z ponad 12-letnim doświadczeniem w korzystaniu z tej chmury oraz 20-letnim doświadczeniem IT. W tym czasie stworzył kilka odnoszących sukcesy i nagradzanych firm, a także współpracował z wieloma innymi, aby doprowadzić je do sukcesu. Uwielbia inżynierię w chmurze ze względu na możliwe automatyzacje i nieograniczone możliwości.",
      },
    ],
    pt: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny é o especialista em criptomoedas da equipe, com vasta experiência e conhecimento da mecânica de jogos P2E e do ecossistema Binance Smart Chain. Ele tem vários anos de experiência no espaço de comércio eletrônico e será responsável pela construção de comunidades e marketing para Crypto Legions. Ele vive e respira criptomoedas e depois de se tornar um trader de criptomoedas de sucesso. Seu objetivo é usar o dinheiro que ganhou para fornecer uma fonte de renda sustentável para muitos mais fãs de criptomoedas.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark tem mais de 20 anos de experiência em negócios, empreendedorismo, vendas e marketing de TI e recrutamento de TI. Ele construiu vários negócios prósperos e criou vários programas de treinamento em comunicação. Ele também é um premiado produtor/diretor de filmes, quebrando recordes em arrecadação de fundos e desenvolvendo filmes para as principais plataformas de streaming. Seu próximo objetivo é criar este jogo de criptomoeda e apresentar os resultados tangíveis em um documentário que educará as massas sobre como navegar no espaço de jogos NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie é um pioneiro da rede com mais de 30 anos em tecnologia e 26 anos online. Ele atuou como CEO e CTO de várias startups da Web3 e trabalha com empresas conhecidas da Fortune 500, incluindo Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil e First Republic Bank. Johnnie mora em Redondo Beach e adora JavaScript, Rust e Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto tem mais de 5 anos de experiência em engenharia blockchain e 8 anos de experiência em engenharia front-end. Como engenheiro de blockchain, ele já construiu muitos ecossistemas Defi na forma de DApps, jogos P2E e mercados NFT em vários blockchains. Ele tem trabalhado como desenvolvedor líder nos últimos 2 anos para muitas empresas de criptomoedas blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake trabalhou em engenharia de blockchain por 4 anos e tem 10 anos de experiência em desenvolvimento de pilha completa. Como desenvolvedor sênior de blockchain, ele construiu muitos DApps, contratos e mercados inteligentes NFT, jogos de criptografia e DAOs. Ele sempre entrega alta qualidade, velocidade rápida, excelente comunicação e criatividade para o projeto.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel é o engenheiro profissional de blockchain full-stack experiente em tecnologia com mais de 8 anos de experiência do zero à implantação. Ele tem profundo conhecimento de sistemas oracle e multi-chain, e construiu vários projetos Defi como Dex's e mercados de ativos on-chain, etc. Código limpo, bem comentado e boas habilidades de comunicação são seus superpoderes.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul é engenheiro de blockchain com 7 anos de experiência em desenvolvimento full-stack e 3 anos como engenheiro de blockchain. Ele construiu muitos projetos de blockchain, como NFT minting, NFT marketplaces, NFT Games, DAO, Defi e DApps. Ele construiu muitos projetos do zero. O desenvolvimento front-end com frameworks SPA é uma de suas principais habilidades.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal é um arquiteto de nuvem com mais de 12 anos de experiência usando essa nuvem, além de 20 anos de experiência em TI. Durante esse período, ele criou várias empresas de sucesso e premiadas, além de trabalhar com várias outras para levá-las ao sucesso. Ele adora a engenharia de nuvem devido às suas possíveis automações e possibilidades ilimitadas.",
      },
    ],
    ro: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny este expertul în cripto-monedă al echipei, cu experiență vastă și cunoștințe despre mecanica jocurilor P2E și ecosistemul Binance Smart Chain. Are câțiva ani de experiență în spațiul de comerț electronic și se va ocupa de construirea comunității și de marketing pentru Crypto Legions. Trăiește și respiră cripto și după ce a devenit un comerciant de criptomonede de succes. Scopul său este să folosească banii pe care i-a câștigat pentru a oferi o sursă de venit durabilă multor fani ai criptografiei.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark are peste 20 de ani de experiență în afaceri, antreprenoriat, vânzări și marketing IT și recrutare IT. A construit mai multe afaceri înfloritoare și a creat mai multe programe de formare în comunicare. El este, de asemenea, un producător/regizor de film premiat, doborând recorduri în strângerea de fonduri și dezvoltă filme pentru cele mai bune platforme de streaming. Următorul său obiectiv este să creeze acest joc cripto-monedă și să prezinte rezultatele tangibile într-un documentar care va educa masele cum să navigheze în spațiul de joc NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie este un pionier net cu peste 30 de ani în tehnologie și 26 de ani online. A fost CEO și CTO pentru numeroase startup-uri Web3 și lucrează cu companii binecunoscute din Fortune 500, inclusiv Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil și First Republic Bank. Johnnie locuiește în Redondo Beach și iubește toate lucrurile JavaScript, Rust și Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto are peste 5 ani de experiență în inginerie blockchain și 8 ani de experiență în inginerie front-end. Ca inginer blockchain, a construit deja multe ecosisteme Defi sub formă de DApps, jocuri P2E și piețe NFT pe mai multe blockchain-uri. El a lucrat ca dezvoltator principal în ultimii 2 ani pentru multe companii de criptomonede blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake a lucrat în inginerie blockchain timp de 4 ani și are 10 ani de experiență în dezvoltarea full stack. În calitate de dezvoltator senior de blockchain, a construit multe DApp-uri, contracte și piețe inteligente NFT, jocuri cripto și DAO. El oferă întotdeauna calitate înaltă, viteză rapidă, comunicare excelentă și creativitate pentru proiect.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel este un inginer profesionist de blockchain cu o experiență de peste 8 ani de la zero până la implementare. Are cunoștințe profunde despre sistemele oracle și multi-lanț și a construit diverse proiecte Defi, cum ar fi piețele de active Dex și on-chain, etc. Cod curat, bine comentat și abilități bune de comunicare sunt superputeri.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul este un inginer blockchain cu 7 ani de experiență în dezvoltarea full-stack și 3 ani ca inginer blockchain. A construit multe proiecte blockchain, cum ar fi baterea NFT, piețele NFT, jocurile NFT, DAO, Defi și DApps. A construit multe proiecte de la zero. Dezvoltarea front-end cu cadre SPA este una dintre principalele sale abilități.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal este un arhitect cloud cu peste 12 ani de experiență în utilizarea acel cloud, precum și 20 de ani de experiență IT. În acest timp, el a creat mai multe companii de succes și premiate, precum și a lucrat cu multe altele pentru a le conduce la succes. Îi place ingineria în cloud datorită posibilelor sale automatizări și posibilităților nelimitate.",
      },
    ],
    ru: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Дэнни — эксперт команды по криптовалютам, обладающий обширным опытом и знаниями игровой механики P2E и экосистемы Binance Smart Chain. Он имеет многолетний опыт работы в сфере электронной коммерции и будет отвечать за создание сообщества и маркетинг для Crypto Legions. Он живет и дышит криптовалютой, а после стал успешным трейдером криптовалюты. Его цель — использовать заработанные деньги, чтобы обеспечить устойчивый источник дохода многим другим поклонникам криптовалюты.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark имеет более чем 20-летний опыт работы в сфере бизнеса, предпринимательства, продаж и маркетинга в сфере ИТ, а также подбора персонала в сфере ИТ. Он построил несколько процветающих предприятий и создал несколько программ обучения общению. Он также является отмеченным наградами кинопродюсером/режиссером, бьющим рекорды по сбору средств и разработке фильмов для ведущих потоковых платформ. Его следующая цель — создать эту криптовалютную игру и показать ее ощутимые результаты в документальном фильме, который научит массы ориентироваться в игровом пространстве NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Джонни — пионер сети с более чем 30-летним опытом работы в сфере технологий и 26-летним опытом работы в Интернете. Он работал генеральным директором и техническим директором во многих стартапах Web3 и работает с известными компаниями из списка Fortune 500, включая Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil и First Republic Bank. Джонни живет в Редондо-Бич и любит все, что связано с JavaScript, Rust и Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Альберто имеет более чем 5-летний опыт разработки блокчейнов и 8-летний опыт разработки интерфейсов. Как блокчейн-инженер, он уже создал множество экосистем Defi в виде DApps, P2E-игр и торговых площадок NFT на нескольких блокчейнах. Последние 2 года он работал ведущим разработчиком во многих криптовалютных компаниях, работающих на блокчейне.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Юяке работал в сфере разработки блокчейнов 4 года и имеет 10-летний опыт разработки полного стека. Как старший разработчик блокчейнов, он создал множество DApps, смарт-контрактов и торговых площадок NFT, криптоигр и DAO. Он всегда обеспечивает высокое качество, быструю скорость, отличную коммуникацию и креативность для проекта.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Ангел — технически подкованный профессиональный блокчейн-инженер с более чем 8-летним опытом работы с нуля до развертывания. Он обладает глубокими знаниями о системах оракулов и мультицепочках, а также создал различные проекты Defi, такие как Dex, рынки сетевых активов и т. д. Чистый, хорошо прокомментированный код и хорошие коммуникативные навыки — его сверхспособности.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Пол — блокчейн-инженер с 7-летним опытом разработки полного стека и 3 годами работы блокчейн-инженером. Он создал множество блокчейн-проектов, таких как чеканка NFT, торговые площадки NFT, NFT Games, DAO, Defi и DApps. Многие проекты он построил с нуля. Фронтенд-разработка с использованием SPA-фреймворков — один из его основных навыков.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Рафал — облачный архитектор с более чем 12-летним опытом использования этого облака, а также 20-летним опытом работы в сфере ИТ. За это время он создал несколько успешных и отмеченных наградами компаний, а также работал со многими другими, чтобы привести их к успеху. Он любит облачную инженерию из-за ее возможной автоматизации и безграничных возможностей.",
      },
    ],
    ph: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Si Danny ay ang crypto-currency expert ng team, na may malawak na karanasan at kaalaman sa P2E game mechanics at sa Binance Smart Chain ecosystem. Siya ay may ilang taon ng karanasan sa loob ng espasyo ng e-commerce at mamamahala sa pagbuo ng komunidad at marketing para sa Crypto Legions. Siya ay nabubuhay at humihinga ng crypto at pagkatapos maging matagumpay na mangangalakal ng cryptocurrency. Ang kanyang layunin ay gamitin ang pera na ginawa niya upang magbigay ng isang napapanatiling mapagkukunan ng kita sa marami pang mga tagahanga ng crypto.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Ang Shark ay may higit sa 20 taong karanasan sa negosyo, entrepreneurship, IT sales at marketing, at IT recruitment. Nagtayo siya ng ilang umuunlad na negosyo, at lumikha ng maraming programa sa pagsasanay sa komunikasyon. Isa rin siyang award-winning na film producer/director, nagbabasa ng mga record sa fundraising, at nagde-develop ng mga pelikula para sa mga nangungunang streaming platform. Ang kanyang susunod na layunin ay likhain itong crypto-currency na laro at itampok ang mga nakikitang resulta sa isang dokumentaryo na magtuturo sa masa kung paano mag-navigate sa NFT gaming space.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Si Johnnie ay isang net pioneer na may mahigit 30 taon sa tech at 26 na taon online. Naglingkod siya bilang CEO at CTO para sa maraming Web3 startup at nakikipagtulungan sa mga kilalang Fortune 500 na kumpanya kabilang ang Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil, at First Republic Bank. Nakatira si Johnnie sa Redondo Beach at gustung-gusto niya ang lahat ng bagay na JavaScript, Rust, at Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Si Alberto ay may higit sa 5 taong karanasan sa blockchain engineering at 8 taong karanasan sa front-end engineering. Bilang isang blockchain engineer, nakagawa na siya ng maraming Defi ecosystem sa anyo ng DApps, P2E games at NFT marketplaces sa ilang blockchain. Nagtatrabaho siya bilang lead developer sa nakalipas na 2 taon para sa maraming kumpanya ng blockchain cryptocurrency.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Si Yuyake ay nagtrabaho sa blockchain engineering sa loob ng 4 na taon, at may 10 taong karanasan sa buong stack development. Bilang isang senior blockchain developer, nakagawa siya ng maraming DApps, NFT smart contracts at marketplaces, crypto games, at DAOs. Palagi siyang naghahatid ng mataas na kalidad, mabilis na bilis, mahusay na komunikasyon at pagkamalikhain para sa proyekto.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Si Angel ay ang tech-savvy na propesyonal na full-stack blockchain engineer na may higit sa 8 taong karanasan mula sa simula hanggang sa pag-deploy. Mayroon siyang malalim na kaalaman sa mga oracle system at multi-chain, at nakagawa siya ng iba't ibang proyekto ng Defi tulad ng Dex's at on-chain asset marketplaces, atbp. Malinis, may mahusay na komentong code, at mahusay na kasanayan sa komunikasyon ang kanyang mga superpower.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Si Paul ay isang blockchain engineer na may 7 taong karanasan sa full-stack development, at 3 taon bilang isang blockchain engineer. Nagtayo siya ng maraming proyektong blockchain tulad ng NFT minting, NFT marketplaces, NFT Games, DAO, Defi, at DApps. Nagtayo siya ng maraming proyekto mula sa simula. Ang front-end development na may mga SPA framework ay isa sa kanyang mga pangunahing kasanayan.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Si Rafal ay isang Cloud Architect na may higit sa 12 taong karanasan sa paggamit ng cloud na iyon pati na rin sa 20 taong karanasan sa IT. Sa panahong iyon, nakagawa siya ng ilang matagumpay at award-winning na kumpanya pati na rin nakipagtulungan sa marami pang iba upang pangunahan sila sa tagumpay. Gustung-gusto niya ang cloud engineering dahil sa mga posibleng automation nito at walang limitasyong mga posibilidad.",
      },
    ],
    tr: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny, P2E oyun mekaniği ve Binance Akıllı Zincir ekosistemi hakkında engin deneyim ve bilgi birikimine sahip ekibin kripto para birimi uzmanıdır. E-ticaret alanında birkaç yıllık deneyime sahip ve Crypto Legions için topluluk oluşturma ve pazarlamadan sorumlu olacak. Başarılı bir kripto para tüccarı olduktan sonra kripto yaşıyor ve nefes alıyor. Amacı, kazandığı parayı daha fazla kripto hayranına sürdürülebilir bir gelir kaynağı sağlamak için kullanmak.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark, iş, girişimcilik, BT satış ve pazarlama ve BT işe alım konularında 20 yılı aşkın deneyime sahiptir. Birkaç gelişen işletme kurdu ve çoklu iletişim eğitim programları oluşturdu. Aynı zamanda ödüllü bir film yapımcısı/yönetmeni, bağış toplamada rekorlar kırıyor ve en iyi yayın platformları için filmler geliştiriyor. Bir sonraki amacı, bu kripto para birimi oyununu yaratmak ve somut sonuçları, kitleleri NFT oyun alanında nasıl gezineceklerini eğitecek bir belgeselde sunmak.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie, teknolojide 30 yılı aşkın ve çevrimiçi ortamda 26 yılı aşkın süredir net bir öncüdür. Çok sayıda Web3 girişiminde CEO ve CTO olarak görev yaptı ve Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil ve First Republic Bank gibi tanınmış Fortune 500 şirketleri ile çalışıyor. Johnnie Redondo Beach'te yaşıyor ve JavaScript, Rust ve Unity3D ile ilgili her şeyi seviyor.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto, blok zinciri mühendisliğinde 5 yıldan fazla deneyime ve ön uç mühendisliğinde 8 yıldan fazla deneyime sahiptir. Bir blok zinciri mühendisi olarak, halihazırda birkaç blok zincirinde DApp'ler, P2E oyunları ve NFT pazar yerleri biçiminde birçok Defi ekosistemi inşa etti. Son 2 yıldır birçok blockchain kripto para şirketi için lider geliştirici olarak çalışıyor.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake blok zinciri mühendisliğinde 4 yıl çalıştı ve tam yığın geliştirmede 10 yıllık deneyime sahip. Kıdemli bir blockchain geliştiricisi olarak birçok DApp, NFT akıllı sözleşme ve pazar yeri, kripto oyunu ve DAO inşa etti. Proje için her zaman yüksek kalite, hızlı hız, mükemmel iletişim ve yaratıcılık sunar.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel, sıfırdan uygulamaya kadar 8 yılı aşkın deneyime sahip, teknoloji konusunda bilgili profesyonel tam yığın blok zinciri mühendisidir. Oracle sistemleri ve çoklu zincir hakkında derin bilgiye sahiptir ve Dex'in ve zincir üstü varlık pazarları gibi çeşitli Defi projeleri inşa etmiştir. Temiz, iyi yorumlanmış kod ve iyi iletişim becerileri onun süper güçleridir.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul, tam yığın geliştirmede 7 yıllık deneyime ve blok zinciri mühendisi olarak 3 yıllık deneyime sahip bir blok zinciri mühendisidir. NFT madenciliği, NFT pazaryerleri, NFT Games, DAO, Defi ve DApps gibi birçok blok zinciri projesi inşa etti. Birçok projeyi sıfırdan inşa etti. SPA çerçeveleriyle ön uç geliştirme, ana becerilerinden biridir.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal, bu bulutu kullanma konusunda 12 yıldan fazla deneyime ve 20 yıllık BT deneyimine sahip bir Bulut Mimarıdır. Bu süre zarfında, birkaç başarılı ve ödüllü şirket kurdu ve onları başarıya götürmek için birçok başka şirketle çalıştı. Olası otomasyonları ve sınırsız olanakları nedeniyle bulut mühendisliğini seviyor.",
      },
    ],
    uk: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Денні — експерт команди з криптовалют, який має величезний досвід і знання ігрової механіки P2E та екосистеми Binance Smart Chain. Він має кілька років досвіду роботи в сфері електронної комерції та відповідатиме за створення спільноти та маркетинг для Crypto Legions. Він живе і дихає криптовалютою і після того, як став успішним криптовалютним трейдером. Його мета — використати зароблені гроші, щоб забезпечити стабільне джерело доходу для багатьох шанувальників криптовалют.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Акула має понад 20-річний досвід роботи в бізнесі, підприємництві, ІТ-продажах та маркетингу та ІТ-рекрутингу. Він побудував кілька процвітаючих підприємств і створив численні програми навчання комунікації. Він також відзначений нагородами кінопродюсер/режисер, б'ючи рекорди у зборі коштів та розробці фільмів для провідних потокових платформ. Його наступна мета — створити цю криптовалютну гру та показати відчутні результати у документальному фільмі, який навчить мас орієнтуватися в ігровому просторі NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Джонні є піонером мережі, який має понад 30 років у техніці та 26 років онлайн. Він працював генеральним директором і технічним директором багатьох стартапів Web3 і співпрацює з відомими компаніями зі списку Fortune 500, включаючи Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil і First Republic Bank. Джонні живе в Редондо-Біч і любить все, що стосується JavaScript, Rust і Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Альберто має понад 5-річний досвід розробки блокчейну та 8-річний досвід розробки інтерфейсу. Як інженер блокчейну, він уже створив багато екосистем Defi у вигляді DApps, P2E ігор і NFT-маркетплейсів на кількох блокчейнах. Протягом останніх 2 років він працював провідним розробником у багатьох криптовалютних компаніях з блокчейном.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Юяке працював у галузі блокчейн-інженерії протягом 4 років і має 10-річний досвід розробки повного стека. Як старший розробник блокчейну, він створив багато DApps, NFT смарт-контрактів і маркетплейсів, криптоігор і DAO. Він завжди забезпечує високу якість, швидку швидкість, відмінне спілкування та креативність для проекту.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel — професійний інженер з повним стеком блокчейну, що володіє технологіями, з більш ніж 8-річним досвідом роботи від нуля до розгортання. Він глибоко знає системи оракулів і мультиланцюжок, а також створив різні проекти Defi, як-от Dex і ринки активів на ланцюжках тощо. Чистий, добре прокоментований код і хороші комунікативні навички — це його суперсила.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Пол — блокчейн-інженер із 7-річним досвідом розробки повного стека та 3 роки як інженером-блокчейном. Він створив багато блокчейн-проектів, таких як NFT minting, NFT Marketplaces, NFT Games, DAO, Defi та DApps. Він створив багато проектів з нуля. Фронт-енд розробка за допомогою фреймворків SPA – одна з його головних навичок.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Рафал — хмарний архітектор із понад 12-річним досвідом використання цієї хмари, а також 20-річним досвідом роботи в ІТ. За цей час він створив кілька успішних і відзначених нагородами компаній, а також співпрацював з багатьма іншими, щоб привести їх до успіху. Він любить хмарну інженерію через її можливі автоматизації та безмежні можливості.",
      },
    ],
    vi: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny là chuyên gia tiền điện tử của nhóm, với kinh nghiệm và kiến thức sâu rộng về cơ chế trò chơi P2E và hệ sinh thái Chuỗi thông minh Binance. Anh ấy có nhiều năm kinh nghiệm trong lĩnh vực thương mại điện tử và sẽ phụ trách xây dựng cộng đồng và tiếp thị cho Crypto Legions. Anh ấy sống và hít thở tiền điện tử và sau khi trở thành một nhà giao dịch tiền điện tử thành công. Mục tiêu của anh ấy là sử dụng số tiền kiếm được để cung cấp nguồn thu nhập bền vững cho nhiều người hâm mộ tiền điện tử hơn.",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark có hơn 20 năm kinh nghiệm trong lĩnh vực kinh doanh, khởi nghiệp, bán hàng & tiếp thị CNTT và tuyển dụng CNTT. Ông đã xây dựng một số công việc kinh doanh phát đạt và tạo ra nhiều chương trình đào tạo về giao tiếp. Anh ấy cũng là nhà sản xuất / đạo diễn phim từng đoạt giải thưởng, phá kỷ lục trong việc gây quỹ và phát triển phim cho các nền tảng phát trực tuyến hàng đầu. Mục đích tiếp theo của anh ấy là tạo ra trò chơi tiền điện tử này và giới thiệu các kết quả hữu hình trong một bộ phim tài liệu sẽ hướng dẫn quần chúng cách điều hướng không gian trò chơi NFT.",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie là nhà tiên phong mạng với hơn 30 năm trong lĩnh vực công nghệ và 26 năm trực tuyến. Ông đã từng là Giám đốc điều hành và CTO cho nhiều công ty khởi nghiệp Web3 và làm việc với các công ty nổi tiếng trong danh sách Fortune 500 bao gồm Google, IBM, New York Times, BCGDV, City of New York, Navient, Shell Oil và First Republic Bank. Johnnie sống ở Redondo Beach và yêu thích tất cả những thứ JavaScript, Rust và Unity3D.",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto có hơn 5 năm kinh nghiệm trong lĩnh vực kỹ thuật blockchain và 8 năm kinh nghiệm trong lĩnh vực kỹ thuật front-end. Là một kỹ sư blockchain, anh ấy đã xây dựng nhiều hệ sinh thái Defi dưới dạng DApps, trò chơi P2E và thị trường NFT trên một số blockchain. Anh ấy đã làm việc với tư cách là nhà phát triển chính trong 2 năm qua cho nhiều công ty tiền điện tử blockchain.",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake đã làm việc trong lĩnh vực kỹ thuật blockchain trong 4 năm và có 10 năm kinh nghiệm trong việc phát triển toàn bộ ngăn xếp. Là một nhà phát triển blockchain cấp cao, anh ấy đã xây dựng nhiều DApp, hợp đồng thông minh NFT và thị trường, trò chơi tiền điện tử và DAO. Anh ấy luôn mang đến chất lượng cao, tốc độ nhanh, khả năng giao tiếp và sáng tạo tuyệt vời cho dự án.",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel là kỹ sư blockchain full-stack chuyên nghiệp am hiểu công nghệ với hơn 8 năm kinh nghiệm từ đầu cho đến khi triển khai. Anh ấy có kiến thức sâu rộng về hệ thống tiên tri và đa chuỗi, và đã xây dựng các dự án Defi khác nhau như thị trường tài sản trên chuỗi và thị trường tài sản trên chuỗi của Dex, v.v. Mã sạch, được nhận xét tốt và kỹ năng giao tiếp tốt là những siêu năng lực của anh ấy.",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul là một kỹ sư blockchain với 7 năm kinh nghiệm trong lĩnh vực phát triển full-stack và 3 năm là kỹ sư blockchain. Ông đã xây dựng nhiều dự án blockchain như đúc tiền NFT, thị trường NFT, Trò chơi NFT, DAO, Defi và DApps. Ông đã xây dựng nhiều dự án từ đầu. Phát triển front-end với các khuôn khổ SPA là một trong những kỹ năng chính của anh ấy.",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal là một Kiến trúc sư đám mây với hơn 12 năm kinh nghiệm sử dụng đám mây đó cũng như 20 năm kinh nghiệm CNTT. Trong thời gian đó, ông đã tạo ra một số công ty thành công và đoạt giải thưởng cũng như làm việc với nhiều công ty khác để dẫn dắt họ đến thành công. Anh ấy yêu thích kỹ thuật đám mây do khả năng tự động hóa và khả năng vô hạn của nó.",
      },
    ],
    cn: [
      {
        name: "Danny",
        role: "Founder",
        avatar: "/assets/images/team/danny.webp",
        bio: "Danny 是团队的加密货币专家，在 P2E 游戏机制和币安智能链生态系统方面拥有丰富的经验和知识。他在电子商务领域拥有多年经验，将负责 Crypto Legions 的社区建设和营销。在成为一名成功的加密货币交易员之后，他生活和呼吸着加密货币。他的目标是用他赚到的钱为更多的加密货币粉丝提供可持续的收入来源。",
      },
      {
        name: "Shark",
        role: "Co-Founder",
        avatar: "/assets/images/team/shark.jpg",
        bio: "Shark 在商业、创业、IT 销售和营销以及 IT 招聘方面拥有超过 20 年的经验。他建立了几家蓬勃发展的企业，并创建了多个沟通培训计划。他还是一位屡获殊荣的电影制片人/导演，打破筹款记录，并为顶级流媒体平台开发电影。他的下一个目标是创建这种加密货币游戏，并在纪录片中展示有形的结果，教育大众如何驾驭 NFT 游戏空间。",
      },
      {
        name: "Johnnie",
        role: "CTO",
        avatar: "/assets/images/team/johnnie.webp",
        bio: "Johnnie 是一位拥有 30 多年技术经验和 26 年在线经验的网络先驱。他曾担任众多 Web3 初创公司的首席执行官和首席技术官，并与著名的财富 500 强公司合作，包括谷歌、IBM、纽约时报、BCGDV、纽约市、Navient、壳牌石油和第一共和国银行。 Johnnie 住在雷东多海滩，热爱 JavaScript、Rust 和 Unity3D。",
      },
      {
        name: "Alberto",
        role: "Lead Blockchain Developer",
        avatar: "/assets/images/team/alberto.webp",
        bio: "Alberto 拥有超过 5 年的区块链工程经验和 8 年的前端工程经验。作为一名区块链工程师，他已经在多个区块链上以 DApp、P2E 游戏和 NFT 市场的形式构建了许多 Defi 生态系统。在过去的 2 年里，他一直担任许多区块链加密货币公司的首席开发人员。",
      },
      {
        name: "Yuyake",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/yuyake.png",
        bio: "Yuyake 从事区块链工程 4 年，拥有 10 年全栈开发经验。作为一名资深的区块链开发人员，他构建了许多 DApp、NFT 智能合约和市场、加密游戏和 DAO。他始终为项目提供高质量、快速、出色的沟通和创造力。",
      },
      {
        name: "Angel",
        role: "Web3 Frontend Developer",
        avatar: "/assets/images/team/angel.webp",
        bio: "Angel 是一位精通技术的专业全栈区块链工程师，拥有超过 8 年的从零到部署的经验。他对预言机系统和多链有深入的了解，并建立了Dex和链上资产市场等各种Defi项目。干净、注释良好的代码和良好的沟通能力是他的超能力。",
      },
      {
        name: "Paul",
        role: "Blockchain Developer",
        avatar: "/assets/images/team/paul.webp",
        bio: "Paul 是一名区块链工程师，拥有 7 年的全栈开发经验，以及 3 年的区块链工程师经验。他建立了许多区块链项目，例如 NFT 铸币、NFT 市场、NFT 游戏、DAO、Defi 和 DApps。他从零开始构建了许多项目。使用 SPA 框架进行前端开发是他的主要技能之一。",
      },
      {
        name: "Rafal",
        role: "DevOps Engineer",
        avatar: "/assets/images/team/rafal.webp",
        bio: "Rafal 是一位云架构师，拥有超过 12 年的云使用经验以及 20 年的 IT 经验。在此期间，他创建了几家成功且屡获殊荣的公司，并与其他多家公司合作，带领他们走向成功。他喜欢云工程，因为它可能实现自动化和无限的可能性。",
      },
    ],
  },
  sectionSecondTeam: {
    en: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    de: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    es: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    fr: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    hi: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    ig: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    it: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    ja: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    nl: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    pl: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    pt: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    ro: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    ru: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    ph: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    tr: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    uk: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    vi: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
    cn: [
      {
        name: "Luke",
        role: "Website Development",
        avatar: "/assets/images/team/luke.webp",
      },
      {
        name: "Fenix",
        role: "Community Manager",
        avatar: "/assets/images/team/luca.webp",
      },
      {
        name: "Marian",
        role: "Community Manager",
        avatar: "/assets/images/team/hamazah.webp",
      },
      {
        name: "Victoria",
        role: "Executive Assistant",
        avatar: "/assets/images/team/victoria.webp",
      },
    ],
  },
};

const sectionFooter = {
  title: {
    en: "It's about $Bloodstone to get started.",
    de: "Es geht um $Bloodstone, um loszulegen.",
    es: "Se trata de $Bloodstone para empezar.",
    fr: "Il s'agit de $Bloodstone pour commencer.",
    hi: "आरंभ करने में लगभग $Bloodstone है।",
    ig: "Ọ bụ ihe dịka $Bloodstone ibido.",
    it: "Si tratta di $Bloodstone per iniziare.",
    ja: "始めるのは約$Bloodstoneです。",
    nl: "Het kost ongeveer $ Bloodstone om te beginnen.",
    pl: "Na początek chodzi o $Bloodstone.",
    pt: "É sobre $Bloodstone para começar.",
    ro: "Este vorba despre $Bloodstone pentru a începe.",
    ru: "Для начала нужно $Bloodstone.",
    ph: "Ito ay tungkol sa $Bloodstone upang makapagsimula.",
    tr: "Başlamak için yaklaşık $ Bloodstone var.",
    uk: "Розпочати про $Bloodstone.",
    vi: "Khoảng $ Bloodstone để bắt đầu.",
    cn: "这是关于 $Bloodstone 开始。",
  },
  companyName: {
    en: "© Crypto Games Agency",
    de: "© Crypto Games Agency",
    es: "© Crypto Games Agency",
    fr: "© Crypto Games Agency",
    hi: "© क्रिप्टो गेम्स एजेंसी",
    ig: "© Crypto Games Agency",
    it: "© Crypto Games Agency",
    ja: "©暗号ゲームエージェンシー",
    nl: "© Crypto Games Agency",
    pl: "© Crypto Games Agency",
    pt: "© Crypto Games Agency",
    ro: "© Crypto Games Agency",
    ru: "© Агентство крипто игр",
    ph: "© Crypto Games Agency",
    tr: "© Crypto Games Agency",
    uk: "© Crypto Games Agency",
    vi: "© Crypto Games Agency",
    cn: "© 加密游戏局",
  },
  privacyPolicy: {
    en: "Privacy Policy",
    de: "Datenschutz-Bestimmungen",
    es: "Política de privacidad",
    fr: "Politique de confidentialité",
    hi: "गोपनीयता नीति",
    ig: "amụma nzuzo",
    it: "politica sulla riservatezza",
    ja: "プライバシーポリシー",
    nl: "Privacybeleid",
    pl: "Polityka prywatności",
    pt: "Política de Privacidade",
    ro: "Politica de Confidențialitate",
    ru: "Политика конфиденциальности",
    ph: "Patakaran sa Privacy",
    tr: "Gizlilik Politikası",
    uk: "Політика конфіденційності",
    vi: "Chính sách bảo mật",
    cn: "隐私政策",
  },
  tearmAndConditions: {
    en: "Terms and Conditions",
    de: "Geschäftsbedingungen",
    es: "Términos y condiciones",
    fr: "Termes et conditions",
    hi: "नियम और शर्तें",
    ig: "Usoro na ọnọdụ",
    it: "Termini e Condizioni",
    ja: "規約と条件",
    nl: "Voorwaarden",
    pl: "Zasady i warunki",
    pt: "Termos e Condições",
    ro: "Termeni si conditii",
    ru: "Условия и положения",
    ph: "Mga Tuntunin at Kundisyon",
    tr: "Şartlar ve koşullar",
    uk: "Правила та умови",
    vi: "Các điều khoản và điều kiện",
    cn: "条款和条件",
  },
};

const sectionROI = {
  roiCalculator: {
    en: "ROI Calculator",
    de: "ROI-Rechner",
    es: "Calculadora de ROI",
    fr: "Calculateur de retour sur investissement",
    hi: "आरओआई कैलकुलेटर",
    ig: "Ihe mgbako ROI",
    it: "Calcolatore del ROI",
    ja: "ROI計算機",
    nl: "ROI-calculator",
    pl: "Kalkulator zwrotu z inwestycji",
    pt: "Calculadora de ROI",
    ro: "Calculator ROI",
    ru: "Калькулятор рентабельности инвестиций",
    ph: "ROI calculator",
    tr: "yatırım getirisi hesaplayıcısı",
    uk: "Калькулятор рентабельності інвестицій",
    vi: "Máy tính ROI",
    cn: "投资回报率计算器",
  },
};

export default {
  section1,
  sectionTeam,
  sectionRoadmap,
  sectionFooter,
  sectionROI,
};
