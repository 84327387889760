const navLinks = {
  buyBLSTLink:
    'https://pancakeswap.finance/swap?outputCurrency=0x10cb66ce2969d8c8193707A9dCD559D2243B8b37&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  whitePaper: 'https://docs.bigcrypto.game/',
  play: 'https://play.bigcrypto.game/',
  presale:
    'https://www.pinksale.finance/launchpad/0x4bB0A2Df3b0F25545bA6301FD44b801A094918b8?chain=BSC',
  discord: 'https://bigcrypto.game/d',
  telegram: 'https://bigcrypto.game/t',
  twitter: 'https://bigcrypto.game/tw',
  youtube: 'https://bigcrypto.game/y',
  medium: 'https://bigcrypto.game/m',
  company: 'https://www.cryptogames.agency',
  policy: 'https://bigcrypto.game/privacy-policy/',
  termAndConditions: 'https://bigcrypto.game/terms-and-conditions/',
  binance:
    'https://bscscan.com/address/0x10cb66ce2969d8c8193707A9dCD559D2243B8b37',
  certik: 'https://bigcrypto.game/certik',
  chainlink: 'https://bigcrypto.game/m',
  poocoin:
    'https://poocoin.app/tokens/0x10cb66ce2969d8c8193707a9dcd559d2243b8b37',
  dextools:
    'https://www.dextools.io/app/bsc/pair-explorer/0x47bcdda79b5b75c143864234b61205e40b8a09f5',
  coinmarketcap:
    'https://coinmarketcap.com/currencies/crypto-legions-bloodstone/',
  coingecko: 'https://www.coingecko.com/en/coins/crypto-legions-bloodstone',
  agency: 'https://cryptogames.agency',
  whitelist: 'https://bigcrypto.game/apply-whitelist',
};

export default navLinks;
