import React, { useState, useEffect, createContext } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import ParticlesBg from 'particles-bg';
import ReactPlayer from 'react-player/youtube';
import classNames from 'classnames';

import NavBar from '../../components/NavBar/NavBar';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import './main.scss';
import { MdClose } from 'react-icons/md';
import SectionROI from './SectionROI';

export default function Home() {
  const [offset, setOffset] = useState(0);
  const [trailerOpen, setTrailerOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div
      className={classNames({ 'header-sticky': offset > 200 }, 'main-app')}
      id="main"
    >
      <NavBar setTrailerOpen={setTrailerOpen} />
      <Section1 setTrailerOpen={setTrailerOpen} />
      <div style={{ height: '1px', background: 'transparent' }}></div>
      <Section2 />
      <div style={{ height: '1px' }}></div>
      <SectionROI />
      <div style={{ height: '1px' }}></div>
      <Section5 />

      <Transition show={trailerOpen}>
        <Dialog
          onClose={(_reason) => {
            console.log(_reason);
            // setTrailerOpen(false);
          }}
          className="relative"
          style={{ zIndex: 10000 }}
        >
          <div className="fixed inset-0 modal-backdrop" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto rounded lg:w-1/2 xs:w-3/4 relative">
              <div
                style={{
                  width: '100%',
                  position: 'relative',
                  paddingTop: '56.25%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ReactPlayer
                    url="https://youtu.be/GiJyDYNjv6A"
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
                {/* <video autoPlay muted loop id="main-trailer">
                <source src="/assets/video/trailer.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video> */}
                <MdClose
                  className="text-3xl text-gray-400 absolute -top-8 -right-8 cursor-pointer"
                  onClick={() => setTrailerOpen(false)}
                />
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
