import React, { useContext } from "react";
import ActionBtns from "../../components/ActionBtns";
import ParticleBG from "../../components/Layout/ParticleBG";
import language from "../../config/language";
import { LanguageContext } from "../../LanguageContext";

export default function Section1(props) {
  const { setTrailerOpen } = props;

  const [localLang] = useContext(LanguageContext);

  return (
    <div className="section1" id="section1">
      <ParticleBG />
      <div className="top-cloud">
        <img src="/assets/images/top-cloud.png" />
      </div>
      <div className="main-bottom-cave">
        <img src="/assets/images/bottom-section-img.png" />
      </div>
      <div className="section1-content">
        <img
          src="/assets/images/logo.png"
          loading="eager"
          alt="Crypto Legions"
          data-xblocker="passed"
          style={{ visibility: "visible", margin: "auto" }}
          className="xs:w-1/2 xs:hidden lg:block"
        />
        <div className="px-8 f-h2 font-bold text-white text-center py-4 section1-content-title text-shadow">
          {language.section1.gameTitle1[localLang]}
          <br />
          {language.section1.gameTitle2[localLang]}
          <sup className="text-xs" style={{ top: "-15px" }}>
            TM
          </sup>{" "}
          {/* {language.section1.gameTitle3[localLang]} */}
        </div>
        <ActionBtns setTrailerOpen={setTrailerOpen} />
        <div className="px-8 f-sub text-white text-center pt-8 py-4 section1-content-title text-shadow">
          {language.section1.gameStartTitle[localLang]}
        </div>
        <div className="flex justify-center items-center mt-10">
          <div
            className="px-4 flex items-center"
            style={{ borderRight: "1px solid #eeeeee55", width: "170px" }}
          >
            <img src="/assets/images/social/certik.png" width={"100%"} />
          </div>
          <div
            className="px-4 flex items-center"
            style={{ borderRight: "1px solid #eeeeee55", width: "170px" }}
          >
            <img src="/assets/images/social/chainlink.png" width={"100%"} />
          </div>
          <div className="px-4 flex items-center" style={{ width: "170px" }}>
            <img src="/assets/images/social/binance.png" width={"100%"} />
          </div>
        </div>
      </div>
    </div>
  );
}
